export const getParamsObjectFromUrl = (url: string): any => {
  const paramsObject = {}
  url.split('?')[1].split('&').forEach((param: string) => {
    const paramKey = param.split('=')[0];
    const paramValue = param.split('=')[1];
    if(Object.keys(paramsObject).includes(paramKey)){
      const paramsArray: Array<string> = [paramValue];
      paramsObject[paramKey] = paramsArray.concat(paramsObject[paramKey]);
    }else{
      Object.assign(paramsObject, {[paramKey]: paramValue});
    }
  });
  return paramsObject;
}