import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-parties-form',
  templateUrl: './parties-form.component.html',
  styleUrls: ['./parties-form.component.less'],
})
export class PartiesFormComponent implements OnInit {
  @Output() attached = new EventEmitter();
  activeIndex: number;

  partiesForm = this.fb.group({
    partiesArray: this.fb.array([], Validators.required),
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    // const arrayValue = this.form.value[this.formArrayName];
    // if (!arrayValue || !arrayValue.length) {
    //   // this.addControls();
    // } else {
    //   this.activeIndex = arrayValue.length - 1;
    // }
    this.addElement();
  }

  get formArrayElement(): FormArray {
    return this.partiesForm.get('partiesArray') as FormArray;
  }

  addElement() {
    this.formArrayElement.push(
      this.fb.group({
        name: ['', Validators.required],
        socialSecurityNumber: ['', Validators.required],
      })
    );

    this.activeIndex = this.formArrayElement.controls.length - 1;

    this.formArrayElement.controls[this.activeIndex].reset();
  }

  edit(index: number) {
    const previousActiveIndex = this.activeIndex;

    if (!this.formArrayElement.controls[previousActiveIndex].valid) {
      this.remove(previousActiveIndex);
      this.activeIndex = previousActiveIndex < index ? index - 1 : index;
    } else {
      this.activeIndex = index;
    }
  }

  remove(index: number) {
    this.formArrayElement.removeAt(index);
    this.activeIndex = this.activeIndex > index ? this.activeIndex - 1 : this.activeIndex;
  }

  ngOnDestroy() {
    const arrayValue = this.formArrayElement.value;
    if (
      !this.formArrayElement.controls[this.activeIndex].valid &&
      arrayValue &&
      arrayValue.length > 1
    ) {
      this.remove(this.activeIndex);
    }
  }

  submit() {
    this.attached.emit(
      this.formArrayElement.controls
        .filter((partyItem: AbstractControl) => partyItem.valid)
        .map((partyItem: AbstractControl) => partyItem.value)
    );
  }
}
