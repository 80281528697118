import { Directive, OnInit } from '@angular/core';
import { HeartbeatService } from '@app/shell/services/heartbeat.service';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { fromEvent, interval, merge } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[userInactivity]'
})
export class UserInactivityDirective implements OnInit{

  constructor(private heartbeatService: HeartbeatService,
              private authLogicService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.heartbeat();
  }

  heartbeat(): void {
    const intervals = this.authLogicService.getAllowedDowntime();

    if (intervals) { 
      const events = merge(fromEvent(document, 'keypress'),
        fromEvent(document, 'mousemove'));
      interval(intervals.inactivityThreashold)
        .pipe(
          switchMap(() => events),
          debounceTime(intervals.heartbeatInterval),
          switchMap(() => this.heartbeatService.heartBeat())
        ).subscribe()
    }
  }

}
