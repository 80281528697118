import { TreeNode } from 'primeng/api';
import { Injectable } from '@angular/core';

export interface TreeNodeWithRoot extends TreeNode {
  root?: TreeNode;
}

@Injectable({
  providedIn: 'root',
})
export class FindTreeNodeRecursion {
  constructor() {}

  highlightNode(organizationId: number | string, options: any, nodeField: string = 'data') {
    const organization: TreeNodeWithRoot = getSelectedNodeName(options, organizationId, nodeField);
    if (!organization || !options) {
      return;
    }

    organization.expanded = true;
    options.forEach((node: TreeNodeWithRoot) =>
      this.findAndExpandRecursive(node, organizationId, nodeField)
    );
    return organization;
  }

  private findAndExpandRecursive(
    node: TreeNodeWithRoot,
    organizationId: number | string,
    nodeField: string = 'data'
  ) {
    if (extractProperty(node, nodeField) == organizationId) {
      node.expanded = true;
      this.findAndExpandRecursiveToRoot(node, nodeField);
    }
    if (node.children) {
      node.children.forEach((childNode: TreeNodeWithRoot) => {
        childNode.root = node;
        this.findAndExpandRecursive(childNode, organizationId, nodeField);
      });
    }
  }

  findAndExpandRecursiveToRoot(node, nodeField: string = 'data') {
    if (extractProperty(node, nodeField)) {
      node.expanded = true;
    }
    if (node.root && extractProperty(node.root, nodeField)) {
      this.findAndExpandRecursiveToRoot(node.root, nodeField);
    }
  }
}

export const findTreeNodeRecursion = (
  node: any,
  searchValue: any,
  nodeField: string,
  childrenKey = 'children'
): TreeNode => {
  if (node && extractProperty(node, nodeField) == searchValue) {
    return node;
  }

  let result;
  if (node && node?.[childrenKey] && node?.[childrenKey]?.length) {
    node?.[childrenKey].forEach((childrenNode) => {
      if (!!result) {
        return null;
      }
      result = findTreeNodeRecursion(childrenNode, searchValue, nodeField, childrenKey);
    });
    return result;
  }
};

export const getSelectedNodeName = (
  nodes: TreeNode[],
  value: any,
  nodeField: string = 'data'
): TreeNode => {
  if (!nodes) {
    return null;
  }
  let result;
  nodes.forEach((option: any) => {
    if (!!result) {
      return result;
    }
    result = findTreeNodeRecursion(option, value, nodeField);
  });
  return result;
};

export const extractProperty = (item, path) => {
  if (path.indexOf('.') === -1) {
    return item?.[path];
  }
  const field = path.slice(0, path.indexOf('.'));
  return extractProperty(item?.[field], path.substring(path.indexOf('.') + 1));
};
