import { DocumentsService } from './services/documents.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentsRoutingModule } from './documents-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, DocumentsRoutingModule],
  providers: [
    DocumentsService
  ]
})
export class DocumentsModule {
}
