import { WorkspacesService } from '../services/rest-services/workspaces.service';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { ClientStorageService } from '../services/client-storage.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';

@Injectable()
export class UserResolver implements Resolve<Observable<any>> {
  constructor(
    private authenticationService: AuthenticationService,
    private workspacesService: WorkspacesService,
    private storage: ClientStorageService,
    private authenticationRESTService: AuthenticationRESTService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const workspaceId = route.params.workspaceId;
    if (!workspaceId || workspaceId === 'default') {
      return of({});
    }
    return this.authenticationRESTService.getUserByWorkspaceId(route.params.workspaceId).pipe(
      tap({
        next: data => {
          this.workspacesService.getSetting().subscribe();
          if (data) {
            this.authenticationService.resetStorageContent();
            this.storage.setValue(this.storage.KEYS.USER, data);
            this.storage.setValue(this.storage.KEYS.SELECTED_LANGUAGE, data.activeLanguage);
          }
        }
      }),
    );
  }
}
