export enum ListType {
  All = '',
  Drafts = 'drafts',
  Inbox = 'inbox',
  Outbox = 'outbox',
  Filter = 'filter',
  New = 'new',
  Trash = 'trash',
  Statuses = 'statuses',
  Analysis = 'analysis',
}
