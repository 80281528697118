import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class HeartbeatService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationRESTService,
    private authenticationLogicService: AuthenticationService
  ) {}

  public heartBeat(): Observable<void> {
    const wsId = this.authenticationLogicService.getWorkspaceId();
    if (wsId) {
      return this.http.head<void>(`${this.authService.baseUrl()}/Auth/beat`);
    }
    return of(null);
  }
}
