import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserResolver } from './resolvers/user.resolver';
import { WorkspaceResolver } from './resolvers/workspace.resolver';
import { TranslationsResolver } from './resolvers/translations.resolve';
import { LanguageResolver } from './resolvers/language.resolve';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';

import { TranslatePipe } from './pipes/translate.pipe';
import { SortDropdownComponent } from './components/sort-dropdown/sort-dropdown.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { TreePickerComponent } from './components/tree-picker/tree-picker.component';
import { PrimeModule } from './prime/prime.module';
import { DragDropModule } from 'primeng/dragdrop';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { LinkIncidentComponent } from './components/link-incident/link-incident.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { IncidentsTableComponent } from './components/incidents-table/incidents-table.component';
import { LinkedIncidentsComponent } from './components/linked-incidents/linked-incidents.component';
import { AttachPartiesFlowComponent } from './components/attach-parties-flow/attach-parties-flow.component';
import { ConfirmComponent } from './components/attach-parties-flow/confirm/confirm.component';
import { PartiesFormComponent } from './components/attach-parties-flow/parties-form/parties-form.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { DoubleDynamicValidatorComponent } from './helpers/double-dynamic-validator/double-dynamic-validator.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ConfirmationComponent } from './modals/confirmation/confirmation.component';
import { IncidentPreviewComponent } from './components/incident-preview/incident-preview.component';
import { OptionNamePipe } from './pipes/option-name.pipe';
import { ConfirmActionButtonComponent } from './components/confirm-action-button/confirm-action-button.component';
import { IsapPasswordInputComponent } from './components/isap-password-input/isap-password-input.component';
import { IsapToasterComponent } from './components/isap-toaster/isap-toaster.component';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';
import { ProgressBarComponent } from '@app/shared/components/progress-bar/progress-bar.component';
import { MarkdownHelpComponent } from './components/markdown-help/markdown-help.component';
import { MultiTreePickerComponent } from './components/multi-tree-picker/multi-tree-picker.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { EditorModule } from 'primeng/editor';
import { TagModule } from 'primeng/tag';
import { SwitchCasesDirective } from '@app/shared/directives/switch-cases.directive';
import { CopyClipboardDirective } from '@app/shared/directives/copy-clipboard.directive';
import { ResetFormComponent } from './components/reset-form/reset-form.component';
import { LabelComponent } from './components/label/label.component';
import { SearchPipe } from './pipes/search.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { pTableOnRowsChange } from './directives/p-table-on-rows-change.directive';
import { OptionParamsPipe } from './pipes/option-params.pipe';
import { ConfirmationStepComponent } from './components/confirmation-step/confirmation-step.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { ProgressButtonComponent } from './components/progress-button/progress-button.component';
import { AutoresizeDirective } from './directives/autoresize.directive';
import { TableCustomizerComponent } from './components/table-customizer/table-customizer.component';
import { IconPickerComponent } from './components/icon-picker/icon-picker.component';
import { IconPickerModule } from 'ngx-icon-picker';
import { OfflineComponent } from './components/offline/offline.component';
import { SafeHTMLDirective } from './directives/safe-html.directive';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { MessageComponent } from './components/message/message.component';

const sharedComponents = [
  SortDropdownComponent,
  pTableOnRowsChange,
  TreePickerComponent,
  UploadFileComponent,
  SwitchCasesDirective,
  LinkIncidentComponent,
  SpinnerComponent,
  IncidentsTableComponent,
  LinkedIncidentsComponent,
  TabsComponent,
  ConfirmationComponent,
  ConfirmActionButtonComponent,
  IsapPasswordInputComponent,
  IncidentPreviewComponent,
  IsapToasterComponent,
  CopyClipboardDirective,
  ResetFormComponent,
  LabelComponent,
  SafeHTMLDirective,
  ConfirmationStepComponent,
  AutoresizeDirective,
  TextEditorComponent,
  IconPickerComponent,
  TableCustomizerComponent,
  MessageComponent,
  MarkdownHelpComponent,
  OfflineComponent
];

const sharedPipes = [
  DateFormatPipe,
  TruncatePipe,
  TranslatePipe,
  OptionNamePipe,
  SearchPipe,
  ReplacePipe,
  OptionParamsPipe,
];

@NgModule({
  declarations: [
    ...sharedComponents,
    ...sharedPipes,
    AttachPartiesFlowComponent,
    ProgressBarComponent,
    ConfirmComponent,
    PartiesFormComponent,
    TabsComponent,
    DoubleDynamicValidatorComponent,
    StatusIndicatorComponent,
    MultiTreePickerComponent,
    HtmlEditorComponent,
    ErrorDialogComponent,
    ProgressButtonComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeModule,
    EditorModule,
    TagModule,
    IconPickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  //SharedComponent has been exported from SharedModule
  //Import SharedModule into other modules and every component¨
  //registred in the Sharedmodule can be used by other modules
  //import { SharedModule } from "../../app/shared/shared.module";

  exports: [
    ...sharedComponents,
    ...sharedPipes,
    PrimeModule,
    StatusIndicatorComponent,
    ProgressBarComponent,
    MultiTreePickerComponent,
    HtmlEditorComponent,
    ConfirmComponent,
    ProgressButtonComponent
  ],
  providers: [
    LanguageResolver,
    TranslationsResolver,
    WorkspaceResolver,
    UserResolver,
    TranslatePipe,
  ],
})
export class SharedModule {}
