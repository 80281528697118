import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reset-form',
  templateUrl: './reset-form.component.html',
  styleUrls: ['./reset-form.component.less'],
})
export class ResetFormComponent {
  @Input() form: FormGroup = new FormGroup({});

  reset(): void {
    this.form?.reset();
  }
}
