import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  OnChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { IncidentFullObject } from '@app/incidents/models/incident.interface';
import { SortEvent } from 'primeng/api';
import { CustomSortService } from '@app/shared/services/custom-sort.service';
import * as _ from 'lodash';
import { IncidentFilterClass } from '@app/incidents/models/incident-filters.interface';
import { ClientStorageService } from '@app/shared/services/client-storage.service';
import { FieldModel } from '@app/step-builder/models';
import { DateFormat } from '@app/shared/pipes/date-format.pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'app-incidents-table',
  templateUrl: './incidents-table.component.html',
  styleUrls: ['./incidents-table.component.less'],
})
export class IncidentsTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() incidents: IncidentFullObject[];
  @Input() linkedIncidents: IncidentFullObject[] = [];
  @Input() linking: boolean = false;
  @Input() attributes: FieldModel[];
  @Input() selectedIncidents: any[];
  @Input() activeIncidentId: number;
  @Input() isDeleteOption: boolean = true;
  @Input() totalCount: number;
  @Input() set searchInput(value: string) {}
  @Output() rowClicked = new EventEmitter();
  @Output() openInNewTab = new EventEmitter();
  @Output() updateSelection = new EventEmitter();
  @Output() linked = new EventEmitter();
  @Output() onLazyChange = new EventEmitter();
  @ViewChild('dt') incidentTable;
  dateFormat = DateFormat;

  enabledDeleteOption: boolean;
  rows: number = 10;
  private filter: IncidentFilterClass;

  constructor(private customSorting: CustomSortService, private storage: ClientStorageService, private router: Router) {}

  ngOnInit(): void {
    this.initFilter();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.linking && (changes.linkedIncidents || changes.incidents)) {
      this.markLinkedIncidents(this.linkedIncidents, this.incidents);
      this.checkDeleteOption();
    }

    if (changes.incidents) {
      this.checkDeleteOption();
    }
  }

  ngOnDestroy(): void {
    this.router.navigate([], { queryParams: {}, skipLocationChange: true });
  }

  nextPage(e): void {
    const body = new IncidentFilterClass(
      e?.rows,
      e?.first / e?.rows + 1,
      e?.sortField,
      e?.sortOrder > 0
    );
    if (JSON.stringify(this.filter) !== JSON.stringify(body)) {
      this.filter = _.cloneDeep(body);
      this.onLazyChange.emit(body);
    }
  }

  clickOnRow($event: Event, incident: IncidentFullObject) {
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    this.rowClicked.emit(incident);
  }

  customSort(event: SortEvent) {
    const fields = ['incidentNumber', 'fullName', 'name', 'id'];
    this.customSorting.customSort(event, fields);
  }

  markLinkedIncidents(linkedIncidents: IncidentFullObject[], incidents: IncidentFullObject[]) {
    if (!incidents || !incidents.length) {
      return;
    }

    incidents.forEach((incident: IncidentFullObject) => {
      incident.linked = (linkedIncidents || []).some(
        (linkedIncident: IncidentFullObject) => linkedIncident.incidentId === incident.incidentId
      );
    });
  }

  link($event: Event, incident: IncidentFullObject) {
    $event.stopImmediatePropagation();
    $event.stopPropagation();

    this.linked.emit(incident);
  }

  openIncident($event: Event, incident: IncidentFullObject): void {
    $event.stopImmediatePropagation();
    $event.stopPropagation();

    this.openInNewTab.emit(incident);
  }

  private checkDeleteOption(): void {
    this.enabledDeleteOption =
      (this.incidents || []).some((i) => i.privilegeToDelete) && !this.linking;
  }

  private initFilter(): void {
    this.rows = this.storage.getValue(this.storage.KEYS.TABLE_ROWS) || 10;
    this.filter = new IncidentFilterClass(this.rows);
  }

  getConcatenatedNames(array) {
    return array?.map(t => t.name).join(', ');
  }

}
