import { Component } from '@angular/core';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.less']
})
export class OfflineComponent {
  reloadPage(): void {
    location.reload();
  }
}
