import { Injectable } from '@angular/core';
import { DocumentsRESTService } from '@app/documents/services/rest-services/documents.service';
import { MapBufferToBlobService } from '@app/shared/services/map-buffer-to-blob.service';
import { DocumentsLogicServiceInterface } from '@app/documents/models/documents-service.interface';

import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends MessageService implements DocumentsLogicServiceInterface {
  constructor(
    private documentsRESTService: DocumentsRESTService,
    private mapToBlob: MapBufferToBlobService
  ) {
    super();
  }

  openDocument(file): void {
    if (file.url) {
      window.open(`fileEditor?url=${file.url}`, '_blank');
    } else {
      this.add({
        severity: 'warning',
        summary: `Wait for the file to load!`,
      });
    }
  }

  openCSVfileFromArrayBuffer(buffer: ArrayBuffer): void {
    const blob = new Blob([new Uint8Array(buffer)], { type: 'text/csv' });
    const fileName = 'incidents.csv';
    this.downloadBlobFile(blob, fileName);
  }

  openBlobFileInNewTab(blob: Blob, fileName: string = 'isapFile'): void {
    const navigator = window.navigator as any;
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    }
  }

  downloadBlobFile(file: any, fileName?: string): void {
    this.getBlobFromFile(file).then((blob) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName || file.name || file.fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  private getBlobFromFile(file: any): Promise<Blob> {
    return new Promise((resolve) => {
      if (!!file.documentId) {
        this.documentsRESTService.getById(file.documentId).subscribe((res) => {
          resolve(res);
        });
      } else {
        this.fileToBlob(file).then((blob) => {
          resolve(blob);
        });
      }
    });
  }

  private fileToBlob(file: any): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (e) => {
        const arrayBuffer = e.target.result;
        const blob = this.mapToBlob.mapBufferToBlob(arrayBuffer);
        resolve(blob);
      };
      reader.onerror = (error) => reject(error);
    });
  }
}
