import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.less'],
})
export class ConfirmComponent implements OnInit {
  @Output() confirmed = new EventEmitter();
  @Output() canceled = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
