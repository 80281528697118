import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb, BreadcrumbTranslations } from '../models/breadcrumbs.interface';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  visibleBreadcrumbs = {
    //Audits
    [BreadcrumbTranslations.Audit]: true,
    [BreadcrumbTranslations.AuditCreate]: true,
    [BreadcrumbTranslations.AuditCreateInterview]: true,
    [BreadcrumbTranslations.AuditAnalysis]: true,
    [BreadcrumbTranslations.AuditInterview]: true,
    [BreadcrumbTranslations.AuditEdit]: true,
    [BreadcrumbTranslations.AuditList]: true,
    [BreadcrumbTranslations.QuestionSet]: true,
    [BreadcrumbTranslations.QuestionSetAnswers]: true,
    [BreadcrumbTranslations.QuestionSetCreate]: true,
    [BreadcrumbTranslations.Answerset]: true,
    [BreadcrumbTranslations.AnswersetCreate]: true,
    [BreadcrumbTranslations.AnswersetEdit]: true,
    [BreadcrumbTranslations.AnswersetRead]: true,
    //Incidents
    [BreadcrumbTranslations.IncidentCreate]: true,
    [BreadcrumbTranslations.IncidentEdit]: true,
    [BreadcrumbTranslations.Incidents]: true,
    [BreadcrumbTranslations.IncidentRead]: true,
    [BreadcrumbTranslations.IncidentTasks]: true,
    [BreadcrumbTranslations.IncidentMeasures]: true,
    [BreadcrumbTranslations.IncidentLogs]: true,
    [BreadcrumbTranslations.IncidentAnalysis]: true,
    [BreadcrumbTranslations.IncidentGraphics]: true,
    //Helper
    [BreadcrumbTranslations.SettingsLanguage]: true,
    [BreadcrumbTranslations.SettingsNotifications]: true,
    [BreadcrumbTranslations.SettingsUserInfo]: true,
    [BreadcrumbTranslations.SettingsSecurity]: true,
    //Interview
    [BreadcrumbTranslations.InterviewEdit]: true,
    //Tasks
    [BreadcrumbTranslations.TaskCreate]: true,
    [BreadcrumbTranslations.TaskEdit]: true,
    //Organization
    [BreadcrumbTranslations.Organizations]: true,
    //General
    [BreadcrumbTranslations.Params]: true,
    [BreadcrumbTranslations.Home]: true,
    [BreadcrumbTranslations.About]: true,

  };

  getBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (let child of children) {
      let routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('_')
        .replace('/', '');
      url += routeURL ? `/${routeURL}` : '';
      const translation = child.snapshot.data?.breadcrumb?.translation;
      const visible = this.visibleBreadcrumbs?.[translation];
      let breadcrumb: Breadcrumb = {
        translation: translation == BreadcrumbTranslations.Params ? routeURL : translation,
        url: url,
      };
      if (visible) {
        breadcrumbs.push(breadcrumb);
      }
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
