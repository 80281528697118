import { AuthGuard } from './shared/guards/auth.guard';
import { UserResolver } from './shared/resolvers/user.resolver';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LanguageResolver } from './shared/resolvers/language.resolve';
import { TranslationsResolver } from './shared/resolvers/translations.resolve';
import { WorkspaceResolver } from './shared/resolvers/workspace.resolver';
import { LayoutComponent } from './shell/layout.component';
import { APIUrlGuard } from './auth/guards/api.guard';
import { TranslationKey } from './shared/models/translation-key.enum';
import { FileEditorComponent } from '@app/file-editor/components/view/file-editor.component';
import { NotFoundComponent } from './shell/not-found/not-found.component';
import { RedirectTo404Guard } from './shared/guards/redirect-to-404.guard';
import { RoutingParameterTypes } from './shared/models/routing-parameter-types.enum';
import { BreadcrumbTranslations } from './shell/models/breadcrumbs.interface';
import { ApiErrorComponent } from './shell/api-error/api-error.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [APIUrlGuard],
    resolve: {
      language: LanguageResolver,
    },
    children: [
      {
        path: 'auth',
        resolve: {
          translations: TranslationsResolver,
        },
        data: {
          translationContainerKey: TranslationKey.Login,
        },
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'fileEditor',
        component: FileEditorComponent,
      },
      {
        path: 'workspaces',
        canActivate: [AuthGuard],
        children: [
          {
            path: ':workspaceId',
            component: LayoutComponent,
            resolve: {
              workspace: WorkspaceResolver,
              user: UserResolver,
            },
            canActivate: [RedirectTo404Guard],
            data: {
              paramName: 'workspaceId',
              routingType: RoutingParameterTypes.Integer,
              breadcrumb: {
                translation: BreadcrumbTranslations.Home,
              },
            },
            children: [
              {
                path: 'incidents',
                loadChildren: () =>
                  import('./incidents/incident.module').then((m) => m.IncidentModule),
                data: {
                  breadcrumb: {
                    translation: BreadcrumbTranslations.Incidents,
                  },
                },
              },
              {
                path: 'about',
                loadChildren: () =>
                  import('./system-version/system-management.module').then(
                    (m) => m.SystemManagementModule
                  ),
                data: {
                  breadcrumb: {
                    translation: BreadcrumbTranslations.About,
                  },
                },
              },
              {
                path: 'organizations',
                loadChildren: () =>
                  import('./organization/organization.module').then((m) => m.OrganizationModule),
                data: {
                  breadcrumb: {
                    translation: BreadcrumbTranslations.Organizations,
                  },
                },
              },
              {
                path: 'audit',
                loadChildren: () => import('./audit/audit.module').then((m) => m.AuditModule),
                data: {
                  breadcrumb: {
                    translation: BreadcrumbTranslations.Audit,
                  },
                },
              },
              {
                path: 'overview',
                loadChildren: () =>
                  import('./overview/overview.module').then((m) => m.OverviewModule),
                data: {
                  breadcrumb: {
                    translation: BreadcrumbTranslations.Overview,
                  },
                },
              },
              {
                path: 'tasks',
                loadChildren: () => import('./tasks/tasks.module').then((m) => m.TasksModule),
                data: {
                  breadcrumb: {
                    translation: BreadcrumbTranslations.Tasks,
                  },
                },
              },
              {
                path: 'admin',
                loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
                data: {
                  breadcrumb: {
                    translation: BreadcrumbTranslations.Admin,
                  },
                },
              },
              {
                path: 'helper',
                loadChildren: () => import('./helper/helper.module').then((m) => m.HelperModule),
                data: {
                  breadcrumb: {
                    translation: BreadcrumbTranslations.Helper,
                  },
                },
              },
              { path: '', redirectTo: 'incidents', pathMatch: 'full' },
            ],
          },
        ],
      },

      // Do not place any thing below these
      {
        path: '',
        redirectTo: '/auth/sign-in',
        pathMatch: 'full',
      },
      {
        path: 'helper',
        //redirectTo: '/auth/sign-in'
        loadChildren: () => import('./helper/helper.module').then((m) => m.HelperModule),
      },
      {
        path: '404',
        component: NotFoundComponent,
        data: {
          translationContainerKey: TranslationKey.Audit,
        },
        resolve: {
          translations: TranslationsResolver,
        },
      },
    ],
  },
  {
    path: 'api-error',
    component: ApiErrorComponent,
  },
  {
    path: '**',
    redirectTo: 'api-error',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
