import { Component, Input, OnInit } from '@angular/core';
import { StatusIndicatorInterface } from '@app/shared/components/status-indicator/models/status-indicator.interface';

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.less'],
})
export class StatusIndicatorComponent implements OnInit {
  @Input() statusProperties: StatusIndicatorInterface;
  @Input() alternateName: string = '';
  @Input() color: string = '';
  @Input() usePills: boolean = false;
  constructor() {}

  ngOnInit(): void { }


  public getTextColor(backgroundColor) {
    if (!backgroundColor) return;

    // Parse the color to get red, green, and blue values
    const color = (backgroundColor?.charAt(0) === '#') ? backgroundColor?.substring(1, 7) : backgroundColor;
    const r = parseInt(color?.substring(0, 2), 16);
    const g = parseInt(color?.substring(2, 4), 16);
    const b = parseInt(color?.substring(4, 6), 16);

    // Calculate luminance
    const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

    // Return black for light backgrounds and white for dark backgrounds
    return (luminance > 186) ? 'black' : 'white';
  }
}
