export interface IncidentFilter {
  pageSize: number;
  pageCount: number;
  orderBy: any;
  orderDescending: boolean;
}

export class IncidentFilterClass implements IncidentFilter {
  pageSize: number = 10;
  pageCount: number = 1;
  orderBy: any;
  orderDescending: boolean = true;
  pageNumber: number = 1;
  columnSet: number;
  constructor(
    pageSize: number = 10,
    pageCount: number = 1,
    orderBy?: any,
    orderDescending: boolean = true,
    columnSet?: number,
  ) {
    this.pageSize = pageSize;
    this.pageCount = pageCount;
    this.orderBy = orderBy;
    this.orderDescending = orderDescending;
    this.pageNumber = pageCount;
    if (columnSet) {
      this.columnSet = columnSet;
    }
  }
}
