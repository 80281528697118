import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { combineLatest, map, Observable, tap, forkJoin } from 'rxjs';

import { ListType } from '@app/shared/models/list-type.enum';
import { Draft } from '@app/step-builder/models/draft.interface';
import { IncidentAction, MarkIncidentsType } from '@app/incidents/models/incident-status.enum';
import { Comment } from '@app/incidents/models/comment.model';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';
import {
  DuplicateData,
  IncidentFullObject,
  IncidentMinObject,
  IncidentsRootObject,
} from '@app/incidents/models/incident.interface';
import { IncidentRESTServiceInterface } from '@app/incidents/models/incident-service.interface';
import { Organization } from '@app/organization/models/organization.interface';
import { Worklists } from '@app/incidents/models/worklist.interface';
import { SharedService } from '@app/shared/services/shared.service';
import { IncidentFilterClass } from '@app/incidents/models/incident-filters.interface';
import { ClientStorageService } from '@app/shared/services/client-storage.service';
import { TableCustomizerService } from '../table-customizer.service';
import { ColumnSet } from '@app/shared/models/column-set.interface';
import { MultipleEditModel } from '@app/step-builder/models';
import { ProgressButtonService } from '@app/shared/services/progress-button.service';
import { isISODate } from '@app/shared/utils/check-if-iso-date';

@Injectable({
  providedIn: 'root',
})
export class IncidentRESTService implements IncidentRESTServiceInterface {

  constructor(
    private http: HttpClient,
    private authenticationRESTService: AuthenticationRESTService,
    private shared: SharedService,
    private storage: ClientStorageService,
    private tableCustomizerService: TableCustomizerService,
    private progressButtonService: ProgressButtonService
  ) {}

  get(
    type: ListType = ListType.All,
    listId = null,
    query: string = '',
    filter?: any,
    columnSetId?: number,
  ): Observable<IncidentsRootObject> {
    const params: any = {};
    if (listId) {
      params.listId = listId;
    }
    if (query) {
      params.SearchString = query;
    }
    if (columnSetId) {
      params.columnset = columnSetId;
    }
    if (!filter) {
      const pageSize = this.storage.getValue(this.storage.KEYS.TABLE_ROWS) || 10;
      filter = new IncidentFilterClass(pageSize);
    }
    const api = `${this.authenticationRESTService.baseUrl()}/Incidents/${type}`;
    return this.http.get<IncidentsRootObject>(api, {
      params: { ...params, ...filter },
    });
  }

  mapIncident(res: IncidentFullObject): IncidentFullObject {
    const incident: IncidentFullObject = res;
    Object.keys(incident).forEach((key: string) => {
      const value = incident[key];
      if (!Number.isNaN(Date.parse(value)) && typeof(value) === 'string' && isISODate(value)) {
        incident[key] = new Date(this.shared.setLocalTime(value.toString()));
      }
    });
    const incidentAmendments = incident?.incidentAmendments?.map((t) => {
      return {
        ...t,
        createdDate: this.shared.setLocalTime(t.createdDate),
      };
    });
    return { ...incident, incidentAmendments };
  }

  create(incidentObj: Draft): Observable<HttpEvent<Draft>> {
    const api = `${this.authenticationRESTService.baseUrl()}/incidents`;
    return this.http.post<Draft>(api, incidentObj, {reportProgress: true, observe: 'events'});
  }

  getByOrganizationId(organizationId: number) {
    const api = `${this.authenticationRESTService.baseUrl()}/Organizations/${organizationId}/Incidents/`;
    return this.http.get<any>(api);
  }

  update(incidentObj: Draft): Observable<HttpEvent<Draft>> {
    const api = `${this.authenticationRESTService.baseUrl()}/incidents/${incidentObj.incident.id}`;
    return this.http.put<Draft>(api, incidentObj, {reportProgress: true, observe: 'events'});
  }

  updateMutipleIncidents(incidentIds: number[], values: MultipleEditModel[]): Observable<unknown> {
    const api = `${this.authenticationRESTService.baseUrl()}/incidents/bulk`;
    const body = { incidentIds, values};
    return this.http.put<unknown>(api, body);
  }

  delete(ids: number[], isSoftDelete: boolean = true): Observable<IncidentMinObject[]> {
    const requests: Array<any> = ids.map((id: number) =>
      this.http.delete<IncidentMinObject>(
        `${this.authenticationRESTService.baseUrl()}/incidents/${id}?softDelete=${isSoftDelete}`
      )
    );
    return combineLatest<IncidentMinObject[]>(requests);
  }

  getById(incidentId: number, queryParams: any = {}, reportProgress: boolean = false): Observable<IncidentFullObject | HttpEvent<IncidentFullObject>> {
    const queryParamsString = new URLSearchParams(queryParams).toString();
    const api = `${this.authenticationRESTService.baseUrl()}/Incidents/${incidentId}?${queryParamsString}`;
    if(reportProgress){
      return this.http.get<any>(api, {reportProgress: true, observe: 'events'})
    }else{
      return this.http
      .get<IncidentFullObject>(api)
      .pipe(map((results: any) => this.mapIncident(results?.incident)));
    }
  } 

  markIncident(type: MarkIncidentsType, incidentId: number, listId?: number): Observable<IncidentFullObject> {
    const api = `${this.authenticationRESTService.baseUrl()}/Incidents/${incidentId}/${type}`;
    const queryParamsString = listId ? `listId=${listId}` : '';
    return this.http.put<IncidentFullObject>(`${api}/?${queryParamsString}`, {});
  }

  getByIds(
    incidentsIds: number[], 
    queryParams: any = {}, 
    reportProgress: boolean = false,
    barId?: string): Observable<(IncidentFullObject | HttpEvent<IncidentFullObject>)[]> {
    const requests = incidentsIds.map((id: number) => {
      return this.getById(id, queryParams).pipe(
        tap(() => {
          if (reportProgress) {
            this.progressButtonService.updateProgressBar(barId);
          }
        }));
      });
    return forkJoin(requests);
  }

  restore(ids: number[]): Observable<IncidentMinObject[]> {
    const requests: Array<any> = ids.map((id: number) =>
      this.http.put<IncidentMinObject>(
        `${this.authenticationRESTService.baseUrl()}/Incidents/trash/${id}`,
        {}
      )
    );
    return combineLatest<IncidentMinObject[]>(requests);
  }

  // Save the incident to API
  save(incidentObj: Draft): Observable<HttpEvent<Draft>> {
    if (!incidentObj.incident.id) {
      return this.create(incidentObj);
    } else {
      return this.update(incidentObj);
    }
  }

  // duplicate report
  duplicate(incidentId: number, duplicateData: Array<DuplicateData>): Observable<IncidentMinObject> {
    const api = `${this.authenticationRESTService.baseUrl()}/incidents/${incidentId}/duplicate`;
    return this.http.post<IncidentMinObject>(api, {attributes: duplicateData});
  }

  // transition state
  updateState(
    incidentId: number,
    state: IncidentAction,
    message: string = ''
  ): Observable<IncidentMinObject> {
    const api = `${this.authenticationRESTService.baseUrl()}/incidents/${incidentId}/state/${state}`;
    return this.http.put<IncidentMinObject>(api, { message });
  }

  // get possible transition states
  getPossibleStates(incidentId: number): Observable<IncidentAction[]> {
    const api = `${this.authenticationRESTService.baseUrl()}/incidents/${incidentId}/state/validStateChanges`;
    return this.http.get<IncidentAction[]>(api, {});
  }

  getByIdFromTrash(incidentId: number): Observable<IncidentFullObject> {
    const api = `${this.authenticationRESTService.baseUrl()}/Incidents/trash/${incidentId}`;
    return this.http
      .get<IncidentFullObject>(api)
      .pipe(map((results: any) => this.mapIncident(results?.incident)));
  }

  getLogsByIncidentId(incidentId: number): Observable<IncidentFullObject> {
    const api = `${this.authenticationRESTService.baseUrl()}/incidents/${incidentId}/actionhistory`;
    return this.http.get<IncidentFullObject>(api);
  }

  addCommentToIncident(
    incidentId: number,
    comment: Comment
  ): Observable<{ id: number; createdDate: Date; createdBy: number; text: string }> {
    const api = `${this.authenticationRESTService.baseUrl()}/incidents/${incidentId}/amendments`;
    return this.http.post<{ id: number; createdDate: Date; createdBy: number; text: string }>(
      api,
      comment
    );
  }

  getOrganizations(includeRootNode = false): Observable<Organization> {
    const api = `${this.authenticationRESTService.baseUrl()}/Organizations?includeRootNode=${includeRootNode}`;

    return this.http.get<Organization>(api).pipe(map((resp) => resp));
  }

  // List incidents based on listid
  getWorkLists(): Observable<Worklists> {
    const api = `${this.authenticationRESTService.baseUrl()}/IncidentLists`;
    return this.http.get<Worklists>(api);
  }

  getExportableIncidentList(ids: number[], format: string = 'pdf', exportData?:  DuplicateData[]): Observable<Blob> {
    const api = `${this.authenticationRESTService.baseUrl()}/incidents/export/details?format=${format}`;
    const body = { objectType: 'Incident', objectIds: ids, attributes: exportData };
    return this.http.post<Blob>(api, body, { responseType: 'blob' as 'json' });
  }

  getExportableIncident(id: number, format: string = 'pdf', exportData?:  DuplicateData[]): Observable<Blob> {
    const api = `${this.authenticationRESTService.baseUrl()}/incidents/${id}/export/details?format=${format}`;
    const body = { attributes: exportData };
    return this.http.post<Blob>(api, body, { responseType: 'blob' as 'json' });
  }

  markGetLockedState(incidentId: number, listId: number, reportProgress: boolean = false) {
    const queryParams = {
      ListId: listId,
      EnableLock: true,
    };
    return this.getById(incidentId, queryParams, reportProgress);
  }

  getColumnSets(): Observable<ColumnSet[]> {
    const api = `${this.authenticationRESTService.baseUrl()}/Incidents/columnsets`;
    return this.http.get<ColumnSet[]>(api).pipe(
      tap((columnSets: ColumnSet[]) => this.tableCustomizerService._columnSets = columnSets));
  }
}
