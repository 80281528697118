import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { Router } from '@angular/router';

import { AuthenticationService } from '@app/auth/services/authentication.service';
import { SharedService } from '@app/shared/services/shared.service';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private _shared: SharedService,
    private authService: AuthenticationRESTService
  ) {}

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // todo: Attach an access token to the request header. Redirect to login in missing.
    const token = this.authenticationService.getToken();

    const useCredentials = true;

    if (request.body) {
      const mappedBody = request.body;
      request = request.clone({
        withCredentials: useCredentials,
        body: mappedBody,
      });
    } else {
      request = request.clone({
        withCredentials: useCredentials,
      });
    }

    if (token) {
      request = this.addHeader(request, { Authorization: `Bearer ${token}` });
    }

    /// Add current timezone as a custom header
    request = this.addHeader(request, { Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    
    return next.handle(request).pipe(
      //tap((event) => {

      //  if (event.type === HttpEventType.Response)
      //  {
      //    console.log("ApiInterceptor: ", request.url);

      //    if (event.body.manualRedirectUrl) {

      //      console.log("ApiInterceptor: manualRedirectUrl ", event.body.manualRedirectUrl);

      //      window.location.href = event.body.RedirectUrl;
      //    }
      //  }
      //  if (event.type === HttpEventType.Sent) {

      //    var test = event.type
      //  }
      //}),

      catchError((error) => {
        if (error.status === 401) {
          return this.handle401Error(request, next);
        }
        return throwError(error);
      })
    ) as any;
  }

  private isValidJSON(str): boolean {
  try {
    JSON.parse(str);
    return true; // String is valid JSON
  } catch (e) {
    return false; // String is not valid JSON
  }
}


  // append header to request
  private addHeader(request: HttpRequest<unknown>, header: any): HttpRequest<unknown> {
    request = request.clone({
      setHeaders: header,
    });

    return request;
  }

  private handleCustom200Redirect(request: HttpRequest<any>, next: HttpHandler) {

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.authenticationService.getRefreshToken();
      if (token) {
        return this.authService.refreshToken(token).pipe(
          switchMap((value: any) => {
            this.isRefreshing = false;
            this.authenticationService.saveToken(value.token);
            this.authenticationService.saveRefreshToken(value.refreshToken);
            this.refreshTokenSubject.next(value.token);
            return next.handle(this.addHeader(request, { Authorization: `Bearer ${value.token}` }));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.authService.logout(false);
            return throwError(err);
          })
        );
      } else {
        if (!request.url.includes('sso')) this.authService.logout(false, true);
        this.isRefreshing = false;
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addHeader(request, token)))
    );
  }

  
}
