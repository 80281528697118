import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//Prime NG
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { TreeModule } from 'primeng/tree';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ChipsModule } from 'primeng/chips';
import { TooltipModule } from 'primeng/tooltip';
import { PasswordModule } from 'primeng/password';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToolbarModule } from 'primeng/toolbar';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ListboxModule } from 'primeng/listbox';
import { SidebarModule } from 'primeng/sidebar';
import { TreeTableModule } from 'primeng/treetable';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { InputSwitchModule } from 'primeng/inputswitch';

const modules = [
  StepsModule,
  ToastModule,
  TreeTableModule,
  SidebarModule,
  CardModule,
  ButtonModule,
  CalendarModule,
  ColorPickerModule,
  TreeModule,
  RadioButtonModule,
  ChipsModule,
  TooltipModule,
  DialogModule,
  DropdownModule,
  PasswordModule,
  OverlayPanelModule,
  MessageModule,
  MessagesModule,
  ButtonModule,
  InputTextModule,
  PanelModule,
  TabViewModule,
  TabMenuModule,
  TableModule,
  DataViewModule,
  ToolbarModule,
  CheckboxModule,
  AutoCompleteModule,
  ConfirmPopupModule,
  AccordionModule,
  ListboxModule,
  SelectButtonModule,
  ToggleButtonModule,
  SplitButtonModule,
  InputNumberModule,
  TriStateCheckboxModule,
  InputSwitchModule,
];
@NgModule({
  declarations: [],
  imports: [CommonModule, ...modules],
  exports: [...modules],
})
export class PrimeModule {}
