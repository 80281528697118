import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPipe',
})
export class SearchPipe implements PipeTransform {
  transform(value: any, input: string): any {
    if (!input || !value) return value;

    input = input.toLowerCase();

    return value.filter(function (data) {
      return JSON.stringify(data).toLowerCase().includes(input);
    });
  }
}
