export enum FieldTypeEnum {
  Input = 'input',
  InputNumber = 'inputNumber',
  InputNumberLocale = 'InputNumberLocale',
  PasswordInput = 'passwordInput',
  Textarea = 'textarea',
  Label = 'label',
  HtmlEditor = 'htmlEditor',
  // ---------
  DatePicker = 'datePicker',
  TimePicker = 'timePicker',
  DefaultTimePicker = 'timePicker2',
  TreePicker = 'treePicker',
  MultiTreePicker = 'multiTreePicker',
  ColorPicker = 'colorPicker',
  IconPicker = 'iconPicker',
  // ---------
  Radiobutton = 'radiobutton',
  Button = 'button',
  Checkbox = 'checkbox',
  Boolean = 'boolean',
  SelectButtonSingle = 'selectButtonSingle',
  SelectButtonMultiple = 'selectButtonMultiple',
  ToggleButtonSingle = 'toggleButtonSingle',
  TriStateCheckbox = 'triStateCheckbox',
  InputSwitch = 'inputSwitch',
  // ---------
  Dropdown = 'dropdown',
  MultiSelect = 'multiselect',
  AutoComplite = 'autoComplete',
  // ---------
  UploadFile = 'uploadFile',
  BtnLink = 'btnLink',
  // ---------
  FormArray = 'FormArray',
  LinkIncident = 'LinkIncident',
  Custom = 'custom',
  // FormObject = 'FormObject',
}
