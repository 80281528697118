import { TreeNode } from 'primeng/api';
import { IsapTreeNode } from '../models/isap-tree-node.interface';
import { PrimeIcons } from 'primeng/api';

export const addKeyValue = (nodes: TreeNode[]) => {
  nodes.forEach((node: TreeNode) => {
    node.key = node.data;
    if(!!node.children){
      addKeyValue(node.children);
    }
  });
  return nodes;
}

export const setDisabledType = (nodes: IsapTreeNode[]) => {
  nodes.forEach((node: IsapTreeNode) => {
    if (node.disabled) {
      node.children = [];
      node.styleClass = 'disabled';
    }
    if (!!node.children) {
      setDisabledType(node.children);
    }
  });
  return nodes;
}