import { ActivatedRoute, Params } from '@angular/router';
import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { MenuItem } from 'primeng/api';
import { NavigationItems, Workspace } from '@app/shared/models/user.interface';
import { ClientStorageService } from '@app/shared/services/client-storage.service';
import { PwaService } from '@app/shared/services/pwa.service';
import { WorkspacesService } from '../../services/rest-services/workspaces.service';
import { WorkspaceSetting } from '../../models/workspace-setting.interface';
import { firstValueFrom } from 'rxjs';
import { AuthenticationRESTService } from '../../../auth/services/rest-service/authentication.service';

@Component({
  selector: 'app-workspaces-dropdown',
  templateUrl: './sort-dropdown.component.html',
  styleUrls: ['./sort-dropdown.component.less'],
})
export class SortDropdownComponent extends UnsubscriberComponent implements OnInit, OnChanges, AfterViewInit {
  //TODO Strong typing
  @Input() options: any[];
  @Input() userProfileData: NavigationItems;
  @Input() label: string;
  @Input() workspaces: Workspace[];
  @Output() optionClicked = new EventEmitter<{ option: any, event: MouseEvent }>();

  public sort: number;
  public activeWorkspaceId: number;
  public workspaceName: string;
  public userCred: {
    name: string,
    email: string,
  };
  isOpen: boolean;
  workspaceKey = 'workspaces/';
  public helpUrl: string;

  constructor(private route: ActivatedRoute,
    private storage: ClientStorageService,
    private pwaService: PwaService,
    private workspaceService: WorkspacesService,
    private authenticationRESTService: AuthenticationRESTService,
    ) {
    super();

    this.route.params.subscribe((params: Params) => {
      this.activeWorkspaceId = params.workspaceId;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.options?.currentValue?.length) {
      for (let option of this.options) {
        if (!option.routerLink.includes('workspaces')) {
          option.thinBorder = true;
          break;
        }
      }

      this.setWorkspaceIcons();
    }
  }

  isEnabled(option: any, settings: WorkspaceSetting) {

    let ret = false;
    
    switch (option.routerLink) {
      case 'help':
        //console.log("enabler", settings.showHelp, !!this.helpUrl);
        if (settings.showHelp)
          ret = settings.showHelp && !!this.helpUrl; // should be &&, but helpUrl does not currently work
        else
          ret = settings.showHelp;
        break;
      case 'downloadpwa':
        ret = settings.enablePWADownload;
        break;
      case 'logout':
        ret = true;
        break;
      case 'about':
        ret = settings.showAbout;
        break;
      case 'usersettings':
        ret = true;
        break;
      default:
        if (option.routerLink.includes('workspaces'))
          ret = true;
        else
          ret = false;
        break;
    }

    return ret;
  }

  async ngOnInit() {
    
    this.checkInitialOptions();
    this.subscribeOnRoute();
    this.getUserDataFromStorage();

    this.helpUrl = await this.getHelpUrl();
    
    var settings: WorkspaceSetting;
    this.workspaceService.setting$.subscribe((s) => { settings = s as WorkspaceSetting });
    this.options = this.options.filter(item => this.isEnabled(item, settings));
  }

  ngAfterViewInit(): void {
    if (this.showDownloadPWAButton()) {
      this.confirm();
    }
  }

  downloadApp(): void {
    this.pwaService.downloadApp();
  }

  showDownloadPWAButton(): boolean {
    return this.pwaService.checkAbilityToDownloadPWA();
  }

  private confirm() {
    const dowloadButton = document.getElementsByClassName('download-btn')[0];
    const popUpConfigs = {
      target: dowloadButton as EventTarget,
      message: 'Download ISAP for better user experience',
      icon: 'pi pi-download',
      rejectVisible: false,
      acceptLabel: 'Got it',
    };
    //this.confirmationService.confirm(popUpConfigs);
  }

  checkInitialOptions() {
    this.route.queryParams.pipe(takeUntil(this.$destroy)).subscribe((params) => {
      if (!params.sort) {
        return;
      }
      this.sort = params.sort;
    });
  }

  selectOption(option: any, event: MouseEvent) {
    this.optionClicked.emit({ option, event });
    this.isOpen = false;
  }

  private subscribeOnRoute() {
    this.route.data
      .pipe(takeUntil(this.$destroy))
      .subscribe((data: { workspace: { workspaceId: number } }) => {
        const id = data.workspace.workspaceId;
        this.setWorkspaceName(id);
      });
  }

  private setWorkspaceName(id: number): void {
    this.workspaceName = `workspaces/${id}`;
  }

  private setWorkspaceIcons(): void {
    this.options.forEach((option: MenuItem) => {
      const workspaceIcon = this.workspaces.find((workspace: Workspace) => 
        workspace.workspaceId === Number(option.id))?.icon;
      if (!!workspaceIcon) {
        option.icon = workspaceIcon;
      } else {
        option.icon = option.routerLink === 'usersettings' ? '-thin fa-gear' : 
                      option.routerLink === 'downloadpwa' ? 'download' :
                      option.routerLink === 'help' ? 'question-circle' :
                      option.routerLink === 'about' ? 'list-alt' :
                      option.routerLink ===  'logout' ? 'arrow-left' : undefined;
      }
    });
  }

  async getHelpUrl(): Promise<string> {
    return await this.authenticationRESTService.getHelpAsync();
  }

  //getHelpUrl(): void {
  //  this.authenticationRESTService.getHelpUrl().subscribe((url) => {
  //    this.helpUrl = url;
  //  });
  //}

  private getUserDataFromStorage(): void {
    const userFromStorage = this.storage.getValue(this.storage.KEYS.USER_PERMISSIONS);
    this.userCred = {
      name: userFromStorage.fullName,
      email: userFromStorage.username,
    };
  }
}
