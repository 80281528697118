import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { LanguageService } from '@app/shared/services/language.service';
import { Observable } from 'rxjs';

import { ClientStorageService } from '../services/client-storage.service';
import { LanguageRESTService } from '@app/shared/services/rest-services/language.service';

@Injectable()
export class TranslationsResolver implements Resolve<Observable<any>> {
  constructor(private languageService: LanguageService,
              private languageRESTService: LanguageRESTService,
              private storage: ClientStorageService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const languageKey = this.storage.KEYS.SELECTED_LANGUAGE;
    const selectedLanguage = this.storage.getValue(languageKey);

    const languageId = selectedLanguage.languageId || route.parent.data.language;
    return this.languageRESTService.getElements(languageId, route.data.translationContainerKey);
  }
}
