import { Injectable } from '@angular/core';
import { Language } from '@app/shared/models/language.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { map } from 'rxjs/operators';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';
import { LanguageRESTServiceInterface } from '@app/shared/models/language-service.interface';

@Injectable({
  providedIn: 'root',
})
export class LanguageRESTService implements LanguageRESTServiceInterface {
  constructor(private http: HttpClient,
              private auth: AuthenticationService,
              private authenticationRESTService: AuthenticationRESTService) {
  }

  // Provide a base url for the incident route
  private baseUri(): string {
    return `${this.authenticationRESTService.env}/configuration`;
  }

  private baseWorkspaceUri(): string {
    let api = `${this.authenticationRESTService.env}/configuration`;

    const profile = this.auth.getUserProfile();

    if (profile !== null && profile.activeWorkspace && profile.activeWorkspace.workspaceId) {
      const wsId = this.auth.getUserProfile().activeWorkspace.workspaceId;

      api = `${this.authenticationRESTService.baseUrl()}/configuration`;
    }

    return api;
  }

  public getLanguages(): Observable<{ defaultLanguage: Language, languages: Language[] }> {
    const api = `${this.baseUri()}/languages`;

    return this.http.get<{ defaultLanguage: Language, languages: Language[] }>(api)
  }

  public getElements(LanguageId: number, container: string): Observable<Language[]> {
    const api = `${this.baseWorkspaceUri()}/elements?languageId=${LanguageId}&container=${container}`;

    return this.http.get<Language[]>(api).pipe(
      map((translations: Language[]) => {
        const translationsObj: any = {};
        translations.forEach((translation: any) => {
          translationsObj[translation.key] = translation;
        });

        return translationsObj;
      })
    );
  }
}
