import { Component, Input } from '@angular/core';

import { IncidentFullObject } from '@incidentModule/models/incident.interface';

export class LinkedIncident extends IncidentFullObject {
  modifiedOn?: Date;
}

@Component({
  selector: 'app-linked-incidents',
  templateUrl: './linked-incidents.component.html',
  styleUrls: ['./linked-incidents.component.less'],
})
export class LinkedIncidentsComponent {
  @Input() linkedIncidents: LinkedIncident[] = [];
  @Input() clickable: boolean;
}
