import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileEditorService } from '@app/file-editor/services/file-editor.service';

@Component({
  selector: 'app-file-editor',
  templateUrl: './file-editor.component.html',
  styleUrls: ['./file-editor.component.less']
})
export class FileEditorComponent implements OnInit {
  file: {
    url: any;
    type: string;
    extension: string;
  };

  constructor(public route: ActivatedRoute,
              private fileEditorService: FileEditorService) {
  }

  ngOnInit(): void {
    this.file = {
      url: this.route.snapshot.queryParams.url,
      ...this.fileEditorService.getInfo(this.route.snapshot.queryParams.url)
    };
  }

}
