import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.less'],
})
export class LabelComponent {
  @Input() required: boolean = false;
  @Input() text: string = '';
}
