import { Injectable } from '@angular/core';
import { SharedServiceInterface } from '@app/shared/models/shared.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedService implements SharedServiceInterface{
  constructor() {}

  setLocalTime(date: string): string {    
    const d = new Date(date);
    const offset = d.getTimezoneOffset();
    const newDate = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes() - offset,
      d.getSeconds()
    );
    return newDate.toISOString();
  }

  getUTCday(date: string | Date): string {
    const d = new Date(date);
    d.setUTCFullYear(d.getFullYear(), d.getMonth(), d.getDate());
    d.setUTCHours(0, 0, 0, 0);
    return d.toISOString();
  }
}
