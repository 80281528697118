import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  WorkspaceServiceInterface,
  WorkspaceSetting,
} from '@app/shared/models/workspace-setting.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspacesService implements WorkspaceServiceInterface {

  setting$ = new BehaviorSubject<WorkspaceSetting>(null);

  constructor(private http: HttpClient,
    private auth: AuthenticationService,
    private authenticationRESTService: AuthenticationRESTService) { }

  get baseUrl(): string {
    return `${this.authenticationRESTService.baseUrl()}`;
  }

  getSetting(): Observable<WorkspaceSetting> {
    const api = `${this.baseUrl}/settings`;
    return this.http.get<WorkspaceSetting>(api)
      .pipe(
        tap((setting: WorkspaceSetting) => {
          this.setting$.next(setting);
        })
      );
  }
}
