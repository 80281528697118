import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { LoaderService } from '@app/shell/services/loader.service';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { Router } from '@angular/router';
import { IsapToasterService } from '../components/isap-toaster/service/isap-toaster.service';
import { ErrorMessage } from '../models/error-message.interface';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private loadingService: LoaderService,
    private authRestService: AuthenticationRESTService,
    private dialogService: DialogService,
    private router: Router,
    public isapToaster: IsapToasterService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Process next interceptor(s) in chain
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleErrorStatusCode(error);
        this.loadingService.hide();
        return throwError(error);
      })
    );
  }

  private handleErrorStatusCode(error: HttpErrorResponse) {
    if (this.okStatusCode(error)) {
      return;
    }
    switch (error.status) {
      case 403: {
        this.handleError(error.error);
        this.isapToaster.$panelMessage.pipe(filter((c) => !c)).subscribe((c) => {
          this.authRestService.logout(false); 
        });
        break;
      }
      case 400: {
        this.openErrorDialog(error);
        break;
      }
      //case 0: {
      //  this.router.navigate(['/api-error']);
      //  break;
      //}
      //// careful! 
      //case 0: {
      //  if (error.ok === false && error.statusText === "Unknown Error") {
      //    this.authRestService.logout(false, true);
      //    break;
      //  }
      //}
    }
  }

  private openErrorDialog(error: HttpErrorResponse): void {
    this.dialogService.open(ErrorDialogComponent, {
      showHeader: false,
      width: '50%', 
      data: error?.error,
    });
  }

  private okStatusCode(error: HttpErrorResponse): boolean {
    const validErrors = ['error.sso'];
    return validErrors.find((e) => e === error.error) !== undefined;
  }

  private handleError(message: ErrorMessage) {
    if (!message?.displayClientSide) {
      return;
    }
    this.isapToaster.$panelMessage.next(message);
  }
}
