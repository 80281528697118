import {
  Language,
  TextElement
} from '../models/language.interface';
import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { LanguageLogicServiceInterface } from '@app/shared/models/language-service.interface';

@Injectable({
  providedIn: 'root'
})
export class LanguageService implements LanguageLogicServiceInterface {
  languages$ = new BehaviorSubject<Array<Language>>(null);
  selectedLanguage$ = new BehaviorSubject<Language>(null);

  constructor() {
  }

  // returns a text-element by key from a supplied list (or default if missing)
  public translate(key: string, elements: any[]): TextElement {
    // set default value
    const standard = { key, text: key } as TextElement;
    let word = standard;

    // try fetching textelement if loaded
    if (elements) {
      word = elements.find((t) => t.key === key);
    }

    // if specific element is missing in list, return default value
    if (!word) {
      word = standard;
    }

    return word;
  }
}
