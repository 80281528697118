import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private beforeInstallPromptEvent;
  private installPromptEventTimesWorked = 0;

  constructor() { }

  get beforeInstallPromptEventData(): any {
    return this.beforeInstallPromptEvent;
  }

  set setBeforeInstallPromptEventData(beforeInstallPromptEvent) {
    this.installPromptEventTimesWorked++;
    this.beforeInstallPromptEvent = beforeInstallPromptEvent;
  }

  checkAbilityToDownloadPWA(): boolean {
    return this.installPromptEventTimesWorked === 1 && !!this.beforeInstallPromptEvent;
  }

  downloadApp(): void {
    this.beforeInstallPromptEventData.prompt();
    this.beforeInstallPromptEventData.userChoice.then(event => {
      const { outcome } = event;
      if (outcome === 'dismissed') {
        window.alert('Refresh page to make download available again');
      }
      this.beforeInstallPromptEvent = undefined;
    });
  }
}
