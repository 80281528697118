import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { pluck, takeWhile, expand, filter, map, first } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(private route: ActivatedRoute, private injector: Injector) {
    this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
  }

  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }

  transform(translationKey: string, property: string = 'text', resolverName: string = ''): any {
    const defaultResolverName: string = 'translations';
    const translation = of(this.route).pipe(
      expand((route) => of(route.parent)),
      takeWhile((route) => !!route.parent),
      pluck('snapshot', 'data'),
      filter(
        (a) =>
          a?.[resolverName]?.[translationKey]?.[property] ||
          a?.[defaultResolverName]?.[translationKey]?.[property]
      ),
      first(null, {}),
      map(
        (b) =>
          this.mapValue(b?.[resolverName]?.[translationKey]?.[property]) ||
          this.mapValue(b?.[defaultResolverName]?.[translationKey]?.[property]) ||
          translationKey
      )
    );
    return this.asyncPipe.transform(translation);
  }

  mapValue(str: string = ''): string {
    const liRegex = /\\(n[*])/g;
    return str?.replace(liRegex, '<li class="marked-list">');
  }
}
