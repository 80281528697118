export enum Extension {
  GIF = 'gif',
  JPEG = 'jpeg',
  Jpg = 'jpg',
  Mp4 = 'mp4',
  PNG = 'png',
  Rtf = 'rtf',
  SVG = 'svg',

  BMP = 'bmp',
  Odp = 'odp',
  Ods = 'ods',
  Odt = 'odt',
  HTM = 'htm',
  HTML = 'html',
  JSON = 'json',
  Key = 'key',
  Pps = 'pps',
  Tex = 'tex',

  PDF = 'pdf',
  Txt = 'txt',
  XML = 'xml',
  Xls = 'xls',
  Xlsx = 'xlsx',
  CSV = 'csv',
  Doc = 'doc',
  Docx = 'docx',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Tsv = 'tsv',
}
