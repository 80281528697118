import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl,  FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-isap-password-input',
  templateUrl: './isap-password-input.component.html',
  styleUrls: ['./isap-password-input.component.less'],
})
export class IsapPasswordInputComponent implements OnInit {
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() controlName: string;
  show: boolean;
  control: AbstractControl;

  constructor(private parentFormGroupDir: FormGroupDirective) {}

  ngOnInit(): void {
    this.control = this.parentFormGroupDir.control.get(this.controlName);
  }
}
