import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Extension } from '@app/file-editor/services/file-editor.interface';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class FileEditorService {
  constructor() {
  }

  getInfo(defaultUrl: string): { extension: string; type: string; } {
    const extension = this.getExtension(defaultUrl);
    const type = this.initialType(extension);
    return {
      type,
      extension
    };
  }

  getExtension(url: string): string {
    return url.split('.').pop();
  }

  initialType(extension: string): string {
    switch (extension) {
      case Extension.JPEG:
      case Extension.Jpg:
      case Extension.GIF:
      case Extension.PNG:
      case Extension.BMP:
      case Extension.SVG:
        return 'image';
      case Extension.Mp4:
      case Extension.PDF:
      case Extension.HTM:
      case Extension.HTML:
        return 'iframe';
      case Extension.Txt:
      case Extension.Rtf:
      case Extension.XML:
      case Extension.Xls:
      case Extension.Xlsx:
      case Extension.CSV:
      case Extension.Doc:
      case Extension.Docx:
      case Extension.Ppt:
      case Extension.Pptx:
      case Extension.Tsv:
      case Extension.Odp:
      case Extension.Ods:
      case Extension.Odt:
      case Extension.JSON:
      case Extension.Key:
      case Extension.Pps:
      case Extension.Tex:
        return 'google';
      default:
        return 'notFound';
    }
  }
}
