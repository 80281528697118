import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { HTMLEditorFeatures } from './interfaces/html-editor.interface';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.less'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class HtmlEditorComponent implements OnInit {
  @Input() controlName: string;
  @Input() displayedFields: HTMLEditorFeatures[] = [
    HTMLEditorFeatures.FieldEditor,
    HTMLEditorFeatures.FontEditor,
    HTMLEditorFeatures.MarginEditor,
  ];
  @Output() onTextChange = new EventEmitter<void>();
  htmlEditorFeatures = HTMLEditorFeatures;
  constructor() {}

  ngOnInit(): void {}

  handleControlChange(): void {
    this.onTextChange.emit();
  }
}
