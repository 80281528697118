import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressButtonService {
  progressBarUpdated: Subject<{id: string, event: HttpEvent<any>}> = new Subject<{id: string, event: HttpEvent<any>}>();
  progressBarReset: Subject<string> = new Subject<string>();

  constructor() { }

  updateProgressBar(id: string, event: HttpEvent<any> | any = {}): void {
    this.progressBarUpdated.next({id, event});
  }

  resetProgressBar(id: string): void {
    this.progressBarReset.next(id);
  }
}
