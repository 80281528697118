import { Component, OnInit } from '@angular/core';
import { combineLatest, fromEvent, interval } from 'rxjs';
import { auditTime, switchMap } from 'rxjs/operators';

import { HeartbeatService } from '@app/shell/services/heartbeat.service';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  providers: [MessageService]
})
export class LayoutComponent implements OnInit {

  constructor(private heartbeatService: HeartbeatService,
              private authLogicService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.heartbeat();
  }

  heartbeat(): void {
    const intervals = this.authLogicService.getAllowedDowntime();
    if (intervals) { 
      const events = [
        fromEvent(document, 'mousemove'),
        fromEvent(document, 'keydown')
      ]
      interval(intervals.inactivityThreashold)
        .pipe(
          switchMap(() => combineLatest(events)),
          auditTime(intervals.heartbeatInterval),
          switchMap(() => this.heartbeatService.heartBeat())
        ).subscribe()
    }
  }
}
