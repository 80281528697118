import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientStorageService } from '@app/shared/services/client-storage.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-api-error',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.less']
})
export class ApiErrorComponent implements OnInit {
  textContent: any;
  public ApiUrl: string;

  constructor(private http: HttpClient,
              private storage: ClientStorageService) { }

  ngOnInit(): void {

    const cacheBusting = new Date().getTime();
    const api = `assets/api.json?v=${cacheBusting}`;
    this.http.get(api).subscribe((data: { API_URL }) => {
      this.ApiUrl = data.API_URL;
    });

    const selectedLanguage = this.storage.getValue(this.storage.KEYS.SELECTED_LANGUAGE)?.languageCode;
    this.http.get('assets/instance/custom/noconnection.json').pipe(take(1))
    .subscribe((result: any) => {
      if (selectedLanguage) {
        this.textContent = result[selectedLanguage];
      } else {
        this.textContent = result.en;
      }
    });
  }
}
