import { Injectable } from '@angular/core';
import {  Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ClientStorageService } from '@app/shared/services/client-storage.service';
import { LanguageRESTService } from '@app/shared/services/rest-services/language.service';
import { LanguageService } from '@app/shared/services/language.service';
import { Language, TextElement } from '@app/shared/models/language.interface';

@Injectable()
export class LanguageResolver implements Resolve<Observable<TextElement[]>> {
  constructor(private languageRESTService: LanguageRESTService,
              private languageService: LanguageService,
              private storage: ClientStorageService) {}

  resolve(): Observable<TextElement[]> {
    return this.languageRESTService.getLanguages().pipe(
      tap(({ languages }: { languages: Array<Language>; defaultLanguage: Language }) => {
        this.languageService.languages$.next(languages);
      }),
      map(({ defaultLanguage }: { defaultLanguage: Language }) => {
        const languageKey = this.storage.KEYS.SELECTED_LANGUAGE;
        let selectedLanguage;
        if (this.storage.valueExists(languageKey)) {
          selectedLanguage = this.storage.getValue(languageKey);
        } else {
          selectedLanguage = defaultLanguage;
        }
        this.storage.setValue(languageKey, selectedLanguage);
        this.languageService.selectedLanguage$.next(selectedLanguage);
        return selectedLanguage.languageId;
      })
    );
  }
}
