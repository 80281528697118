import { Child } from '@app/audit/models/audit.interface';
import { Response } from '@app/audit/modules/interview/modules/response/interfaces/response.interface';
import { FieldModel } from '@app/step-builder/models';
import { TreeNode } from 'primeng/api';

export interface Questionnaire extends TreeNode {
  hierarchyId?: number;
  singleQuestionView?: boolean;
  children?: Child[];
  privilegeToPublish?: boolean;
  privilegeToUnPublish?: boolean;
  isPublished?: boolean;
  type?: Type;
  index?: number;
  triggersQuestionSetItems?: TriggersQuestionSetItems[];
  childId?: number;
  response?: Response;
  displayChildrenFlat?: boolean;
  visible?: boolean;
  currentElement?: Questionnaire;
  data?: number;
  questionSetType?: Type;
  privilegeToChange: boolean;
  allowConfidenceLevelOptions?: boolean;
  confidenceLevels?: Partial<FieldModel>;
  privilegeToDelete?: boolean;
  answerUnknown?: Partial<FieldModel>;
  confidenceLevelId?: number;
  allowAnswerUnknown?: boolean;
  questionSetId?: number;
  id?: number;
  name?: string;
  longName?: string;
  label?: string;
  description?: string;
  allowComments?: boolean;
  allowAttachments?: boolean;
  workspace?: Workspace;
  chapter?: Chapter;
  expandedHelpText?: boolean;
  question?: Question;
  touched?: boolean;
  doRegExValidation?: boolean;
  regEx?: string;
  triggerAnswer?: TriggerAnswer;
  triggerAnswerId?: number;
  parent?: Questionnaire;
  color: string;
}

export interface TriggersQuestionSetItems {
  triggersHierarchyId: number;
  triggeredByAnswerOptionId: number;
  triggeredByHierarchyId: number;
}

export type Questionnaires = Questionnaire[];

export interface QuestionnairePrerequisite {
  privilegeToCreateQuestionSet: boolean;
}

export interface QuestionnaireChild {
  hierarchyId?: number;
  children?: Questionnaire[];
  type?: string;
  chapter?: Chapter;
  question?: Question;
  triggerAnswer?: TriggerAnswer;
  triggerAnswerId?: number;
}

export interface Chapter {
  chapterId?: number;
  text?: string;
  helpText?: string;
  description?: string;
}

export interface Question {
  questionId?: number;
  text?: string;
  answerAttribute?: AnswerSet;
  ambition?: number;
  answerUnknown: Partial<FieldModel>;
  helpText?: string;
  counterMeasure?: string;
}

export interface AnswerSet extends FieldModel {
  answerAttributeId?: number;
  text?: string;
  name: string;
  doRegExValidation: boolean;
  allowMultiple: boolean;
  regEx: string;
}
export interface TriggerAnswer {
  answerId?: number;
  text?: string;
  cost?: number;
  value?: number;
  order?: number;
  answerSet?: Partial<FieldModel>;
}

export interface Workspace {
  workspaceId?: number;
  workspaceName?: string;
  navigationStart?: string;
}

export enum Type {
  Chapter = 'Chapter',
  QuestionSet = 'QuestionSet',
  Question = 'Question',
  SubQuestionLabel = 'SubQuestionLabel',
}
