import { AuthenticationService } from '@app/auth/services/authentication.service';
import { ClientStorageService } from '../services/client-storage.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { LanguageService } from '@app/shared/services/language.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class WorkspaceResolver implements Resolve<Observable<any>> {
  constructor(
    private languageService: LanguageService,
    private storage: ClientStorageService,
    private authenticationService: AuthenticationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    const user = this.authenticationService.getUserProfile();
    if (!user){
      return of({});
    }
    return of(user.activeWorkspace)
  }
}
