import { Component, Input, Output, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, UrlSegment } from '@angular/router';
import { TableCustomizerService } from '@app/incidents/services/table-customizer.service';
import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { ColumnSet } from '@app/shared/models/column-set.interface';
import { FieldModel } from '@app/step-builder/models';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-table-customizer',
  templateUrl: './table-customizer.component.html',
  styleUrls: ['./table-customizer.component.less']
})
export class TableCustomizerComponent extends UnsubscriberComponent implements OnInit{
  attributes: FieldModel[];
  attributesForm: FormGroup;
  location: string;
  selectedSet: ColumnSet;
  @Input() columnSets: ColumnSet[];
  @Output() columnToggled: EventEmitter<Partial<FieldModel>> = new EventEmitter<Partial<FieldModel>>();
  @Output() changedColumnSet: EventEmitter<ColumnSet> = new EventEmitter<ColumnSet>();

  constructor(private fb: FormBuilder, 
              private route: ActivatedRoute,
              private tableCustomizerService: TableCustomizerService) {
    super();
  }
  
  ngOnInit(): void {
    this.subscribeOnRouteParams();
    this.subscribeOnColumnSetUpdate();
  }

  initForm(): void {
    let formObject: any;
    const localStorageData = this.tableCustomizerService.getLocalStorageData();
    if (!!localStorageData && 
        !!localStorageData[this.location] && 
        !!localStorageData[this.location][this.selectedSet.id]) {
      formObject = localStorageData[this.location][this.selectedSet.id];
    } else {
      formObject = { };
      this.attributes.forEach((attribute: FieldModel) => formObject[attribute?.property] = true);
    } 
    this.attributesForm = this.fb.group(formObject);
  }

  toggleColumn(property: string, visible: boolean): void {
    this.columnToggled.emit({property, visible});
    this.setStorageData();
  }

  changeColumnSet(event: { value: ColumnSet }): void {
    this.setActiveColumnSet();
    this.changedColumnSet.emit(event.value);
    this.attributes = event.value.attributes;
  }

  private setStorageData(): void {
    let localStorageData = this.tableCustomizerService.getLocalStorageData();
    if (!localStorageData) {
      localStorageData = {};
    }
    if (!localStorageData[this.location]) {
      localStorageData[this.location] = {};
    }
    localStorageData[this.location][this.selectedSet.id] = this.attributesForm.value;
    this.tableCustomizerService.setLocalStorageData(localStorageData);
  }

  private setActiveColumnSet(): void {
    let localStorageData = this.tableCustomizerService.getLocalStorageData();
    if (!!localStorageData && localStorageData[this.location]) {
      localStorageData[this.location]['activeColumnSetId'] = this.selectedSet.id;
      this.tableCustomizerService.setLocalStorageData(localStorageData);
      return
    }
    if (!localStorageData) {
      localStorageData = {};
    }
    localStorageData[this.location] = { activeColumnSetId: this.selectedSet.id };
    this.tableCustomizerService.setLocalStorageData(localStorageData);
  }

  private subscribeOnRouteParams(): void {
    this.route.queryParams.pipe(
      takeUntil(this.$destroy),
      switchMap((params: Params) => {
        if (params.activeColumnSetId) {
          const activeColumnSetId = params.activeColumnSetId;
          this.selectedSet = this.columnSets
            .find((columnSet: ColumnSet) => columnSet.id === Number(activeColumnSetId));
          
        } else {
          this.selectedSet = this.columnSets[0];
        }
        this.attributes = this.selectedSet.attributes;
        return this.route.parent.url;
      }))
    .subscribe((urlSegments: UrlSegment[]) => {
      this.location = urlSegments[0].path;
    });
  }

  private subscribeOnColumnSetUpdate(): void {
    this.tableCustomizerService.columnSetChanged$.pipe(takeUntil(this.$destroy))
    .subscribe((columnSetId: number) => {
      const activeColumnSet: ColumnSet = this.columnSets.find((set: ColumnSet) => set.id === columnSetId);
      this.selectedSet = activeColumnSet;
      this.initForm();
    });
  }
}

