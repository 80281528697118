export enum RoutingKeys{
  incident_create = 'incident_create',
  incident_drafts = 'incident_drafts',
  incident_outbox = 'incident_outbox',
  incident_inbox = 'incident_inbox'
}

export enum LOG_OUT_ROUTES {
  WelcomeBack = '/auth/welcome-back/',
  SignIn = '/auth/sign-in/',
}