import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UserProfile, WorkSpaceUser } from '@app/shared/models/user.interface';
import { ClientStorageService } from '@app/shared/services/client-storage.service';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { AuthenticationRESTServiceInterface } from '@app/auth/models/authentication-service.interface';
import { BankIdInterface } from '@app/auth/models/bank-id.interface';
import { MethodOfAuth, MethodsOfAuth } from '@app/auth/models/methods-of-auth.interface';
import { InviteModel, UserInviteCredentialsModel } from '@app/auth/models/invite.model';
import { LOG_OUT_ROUTES } from '@app/auth/models/routingkeys.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationRESTService implements AuthenticationRESTServiceInterface {
  // base url for the API
  public env = ``;

  public signInOrOutInProgress: boolean = false;

  constructor(
    private http: HttpClient,
    private storage: ClientStorageService,
    private authenticationLogicService: AuthenticationService
  ) { }

  getDynamicApiUrl(): Observable<any> {
    const cacheBusting = new Date().getTime();
    const api = `assets/api.json?v=${cacheBusting}`;
    return this.http.get(api);
  }

  getClientUrl(): Observable<string> {
    return this.getDynamicApiUrl().pipe(map((res) => res.ISAP_URL));
  }

  getHelpUrl(): Observable<string> {
    return this.getDynamicApiUrl().pipe(map((res) => res.ISAP_HELP));
  }

  async getHelpAsync()  {

    var url = await firstValueFrom(this.getDynamicApiUrl().pipe(map((res) => res.ISAP_HELP)));

    return url;

  }

  baseUrl(workspaceId: string = null): string {
    if (!workspaceId) {
      workspaceId = this.authenticationLogicService.getWorkspaceId();
    }
    if (!workspaceId) {
      this.logout(true);
    }
    return `${this.env}/ws/${workspaceId}`;
  }

  basePath(workspaceId: string = null): string {
    if (!workspaceId) {
      workspaceId = this.authenticationLogicService.getWorkspaceId();
    }
    return `/workspaces/${workspaceId}`;
  }

  getUsers(query: string): Observable<WorkSpaceUser[]> {
    const queryString = !!query ? `SearchString=${query}` : '';
    return this.http.get<WorkSpaceUser[]>(`${this.baseUrl()}/Users?${queryString}`);
  }

  loginAction(loginBody: {
    username: string;
    password: string;
    requestedUrl?: string;
  }): Observable<any> {
    return this.login(loginBody).pipe(
      map((data: WorkSpaceUser) => {
        this.authenticationLogicService.setUserData(data, loginBody);
        this.redirectAction(loginBody.requestedUrl);
      })
    );
  }

  getAuthMethods(): Observable<MethodOfAuth> {
    const path = `/auth/methods`;
    return this.http.get<MethodOfAuth>(this.env + path);
  }

  getHeaders(header: {}): HttpHeaders {
    const headers = new HttpHeaders();
    for (const key in headers) {
      if (header.hasOwnProperty(key)) {
        headers.append(key, header[key]);
      }
    }
    return headers;
  }

  loginId(body: { ssn?: string; orgId?: string }, methodOfAuth: MethodsOfAuth): Observable<HttpResponse<BankIdInterface>> {
    const path = `/auth/${methodOfAuth.toLowerCase()}/StartAuth`;

    if (methodOfAuth.toLowerCase() === 'saml2') {
      // Handle SAML2 authentication (GET request)
      return this.http.get<BankIdInterface>(this.env + path, { observe: 'response' });
    } else {
      // Handle other authentication methods (POST request)
      return this.http.post<BankIdInterface>(this.env + path, body, { observe: 'response' });
    }
  }

  checkAuthId(
    orderRef: string,
    methodOfAuth: MethodsOfAuth
  ): Observable<HttpResponse<WorkSpaceUser>> {
    const path = `/auth/${methodOfAuth.toLowerCase()}/CheckAuth/${orderRef}`;
    return this.http.get<WorkSpaceUser>(this.env + path, { observe: 'response' });
  }

  login(user: WorkSpaceUser): Observable<WorkSpaceUser> {
    const headerContent = {
      'Content-Type': 'application/json',
    };

    const headers = this.getHeaders(headerContent);
    const language = this.storage.getValue(this.storage.KEYS.SELECTED_LANGUAGE);

    const body = {
      username: user.username,
      password: user.password,
      loginProvider: 'Isap',
      loginIP: '0.0.0.0',
      sourceInfo: 'WEB',
      language: language.languageId,
      requestedUrl: user.requestedUrl,
    };

    // authenticate with the api
    return this.http.post<WorkSpaceUser>(`${this.env}/authenticate`, body, { headers });
  }

  logout(welcomeBackScreen: boolean, withoutToken: boolean = false): void {

    this.signInOrOutInProgress = true;

    const navigateTo: LOG_OUT_ROUTES = welcomeBackScreen ? LOG_OUT_ROUTES.WelcomeBack : LOG_OUT_ROUTES.SignIn;
    if (!withoutToken) {
      this.http
        .post<void>(this.env + `/auth/logout`, {
          token: this.authenticationLogicService.getRefreshToken(),
        })
        .subscribe({
          complete: () => this.authenticationLogicService.clearStorageAndLogout(navigateTo),
        });
    } else {
      this.authenticationLogicService.clearStorageAndLogout(navigateTo);
    }
  }

  refreshToken(token: string): Observable<WorkSpaceUser> {
    return this.http.post<WorkSpaceUser>(`${this.env}/auth/refreshtoken`, {
      token,
    });
  }

  checkSSO(loginProvider = 'AD'): Observable<WorkSpaceUser> {
    return this.http.get<WorkSpaceUser>(`${this.env}/auth/sso?LoginProvider=${loginProvider}`, {
      withCredentials: true,
    });
  }

  getSaml2SignInUrl(naviationStart: string): string {

    naviationStart = naviationStart ?? "";
    let clientUrl: string = "";

    // get the client base address
    this.getClientUrl().subscribe((data) => {
      clientUrl = data;
    })

    // return start of auth process from api with desired back-track page address
    const url: string = `${clientUrl}/auth/saml-auth?naviationStart=${naviationStart}`;
    return `${this.env}/auth/Saml2/StartAuth?returnUrl=${url}`;

  }

  callSaml2SignInUrl(naviationStart: string): void {

    const url: string = this.getSaml2SignInUrl(naviationStart);

    this.http.get<any>(url, {
      withCredentials: true,
    }).subscribe((res) => { });

  }


  // PROMISE TO DELIVER A USERPROFILE (USE)
  getUser(): Observable<UserProfile> {
    return this.http.get<UserProfile>(`${this.env}/user/profile`).pipe(
      tap((data: UserProfile) => {
        if (data) {
          this.storage.setValue(
            this.storage.KEYS.SELECTED_WORKSPACE,
            data.activeWorkspace.workspaceId
          );
          this.storage.setValue(this.storage.KEYS.SELECTED_LANGUAGE, data.activeLanguage);
          this.storage.setValue(this.storage.KEYS.USER, data);
          this.authenticationLogicService.profile.set(data);
        }
        else {
          console.log("NO USER DATA");
        }
      })
    );
  }

  getUserByWorkspaceId(workspaceId: string): Observable<WorkSpaceUser> {
    this.storage.setValue(this.storage.KEYS.SELECTED_WORKSPACE, workspaceId);
    return this.http.get<WorkSpaceUser>(`${this.baseUrl(workspaceId)}/User/profile`);
  }

  resetPassword(form: {
    username: string;
    languageId: string;
    callbackURL: string;
  }): Observable<{ username: string; languageId: string; callbackURL: string }> {
    const body = { callbackURL: form.callbackURL, languageId: form.languageId };
    return this.http.post<{ username: string; languageId: string; callbackURL: string }>(
      `${this.env}/reset/${form.username}`,
      body
    );
  }

  changePassword(body: { oldPassword: string; newPassword: string }): Observable<null> {
    Object.assign(body, { token: this.authenticationLogicService.getToken() });
    const url = `${this.baseUrl()}/User/changepassword`;
    return this.http.put<null>(url, body);
  }

  updatePassword(form: { password; token; username }): Observable<WorkSpaceUser> {
    const body = { token: form.token, newPassword: form.password };
    return this.http.put<WorkSpaceUser>(`${this.env}/reset/${form.username}`, body);
  }

  redirectAction(requestedUrl?: string): void {
    this.getUser().subscribe((user: UserProfile) => {
      // successful login, navigate to start
      const workspaceId =
        user && user.activeWorkspace ? user.activeWorkspace.workspaceId : 'default';
      const baseUrl = `/workspaces/${workspaceId}`;
      this.authenticationLogicService.redirectInsideApp(
        baseUrl,
        user?.activeWorkspace,
        requestedUrl
      );
    });
  }

  getSamlUser(): Observable<WorkSpaceUser> {
    return this.http.get<WorkSpaceUser>(`${this.env}/auth/Saml2/User`);
  }

  getInvitation(inviteToken: string): Observable<InviteModel> {
    
    inviteToken = encodeURIComponent(inviteToken);

    return this.http.get<InviteModel>(`${this.env}/auth/invite/?challenge=${inviteToken}`);
  }

  logInWithInvitation(inviteResponseToken: string, forcePasswordReset: Boolean, userData: UserInviteCredentialsModel): Observable<WorkSpaceUser> {

    inviteResponseToken = encodeURIComponent(inviteResponseToken);

    return this.http.put<WorkSpaceUser>(`${this.env}/auth/invite/?invitation=${inviteResponseToken}&forcePasswordReset=${forcePasswordReset}`, {
      ...userData
    });
  }
}
