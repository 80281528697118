import { BehaviorSubject, Observable } from 'rxjs';

export class StateValue<T> {

  constructor(
    data: T
  ) {
    this._current = new BehaviorSubject<T>(data);
    this.observe = this._current.asObservable();
  }

  // USER DATA
  // Keeps the users state (private subscription and emits changes)
  private _current: BehaviorSubject<T>;

  // Observable for components (changes in the state above are emitted and accessable here)
  public observe: Observable<T>;

  // Regular property get for value retrieval
  public get get(): T {
    return this._current.value;
  }

  set(data: T): void {
    this._current.next(data);
  }

}

/*
 *
export class StateValue {

  constructor(
    data: any
  ) {
    this._current = new BehaviorSubject<any>(data);
    this.observe = this._current.asObservable();
  }

  // USER DATA
  // Keeps the users state (private subscription and emits changes)
  private _current: BehaviorSubject<any>;

  // Observable for components (changes in the state above are emitted and accessable here)
  public observe: Observable<any>;

  // Regular property get for value retrieval
  public get get(): any {
    return this._current.value;
  }

  set(data: any): any {
    this._current.next(data);
  }

}

 */
