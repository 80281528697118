import { FieldTypeEnum } from './field-type.enum';
import { OptionsTypeEnum } from './options-type.enum';
import { FieldServerInterface } from '@app/step-builder/models/field-server.interface';

export interface StepBuilderInterface {
  name?: string;
  fields?: FieldModel[];
  required?: boolean;
  translationKey: string;
  privilegeValue?: string;
  visible?: boolean;
  canChange?: boolean;
  id?: number;
  key?: string;
  longName?: string;
  placeholder?: string;
  helpText?: string;
  order?: number;
  parentProperty?: string;
}

export class FieldModel {
  type?: FieldTypeEnum = FieldTypeEnum.Input;
  longName: string = '';
  formControlName: string;
  inputType: FieldTypeEnum;
  required?: boolean = false;
  helpText?: string = ''; // tooltip
  name?: string = ''; // tooltip
  canDelete?: boolean;
  placeholder: string = '';
  options?: any | FieldOption[];
  dependentOn: Array<DependentOn>;
  // options for form field
  optionsUrl?: string;
  optionsType?: OptionsTypeEnum;
  optionKey: string;
  // params to retrieve an options
  paramsArray?: Array<string>;
  params?: {};
  property?: string; // key
  key?: string = ''; // translations
  keyForRapportPage?: string;
  value?: any;
  // for form arrays
  fields?: FieldModel[];
  dateFormat?: string;
  //////////////////
  attributeGroupId?: number;
  id?: any;
  parentAttributeId?: string;
  privilegeValue?: string; // "FULLCONTROL"
  canChange?: boolean; /// permissions
  visible?: boolean; /// permissions
}

export interface DependentOn {
  dependentAttribute: string,
  dependentValue: string | Array<string>,
  singleSelect?: boolean,
}

export interface FieldOption {
  id: number;
  name: string;
}

export interface FormConfig {
  attributeGroups?: StepBuilderInterface[];
  incidentAttributes?: FieldServerInterface[];
}

export interface ParamsValueByKeysFromUrl {
  AreaId?: number;
  CategoryId?: number;
  DetailId?: number;
  OrganizationId?: number;
  DetailItemId?: number;
}

export interface MultipleEditModel {
  attributeId: number,
  propertyMap: string,
  value: any,
}
