import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';
import { MapBufferToBlobService } from '@app/shared/services/map-buffer-to-blob.service';
import { DocumentsRESTServiceInterface } from '@app/documents/models/documents-service.interface';

@Injectable({
  providedIn: 'root',
})
export class DocumentsRESTService implements DocumentsRESTServiceInterface{
  constructor(private authenticationRESTService: AuthenticationRESTService,
              private http: HttpClient,
              private mapToBlob: MapBufferToBlobService) {}

  upload(files: File[]): Observable<any> {
    const formData: FormData = new FormData();
    files.forEach((file: File) => formData.append('files', file, file.name));
    return this.http.post(`${this.authenticationRESTService.baseUrl()}/Documents`, formData);
  }

  getById(documentId: number): Observable<any> {
    return this.http
      .get(`${this.authenticationRESTService.baseUrl()}/Documents/file/${documentId}`, {
        responseType: 'arraybuffer',
      })
      .pipe(map((res) => this.mapToBlob.mapBufferToBlob(res)));
  }

  update(documents: any[]): Observable<any> {
    const updateOneDoc = (doc) => {
      const body = {
        objectId: doc.objectId,
        objectType: doc.objectType,
        involvedParties: doc.involvedParties,
      };
      return this.http.put(`${this.authenticationRESTService.baseUrl()}/Documents/file/${doc.documentId}`, body);
    };
    const requests = documents.map((d) => updateOneDoc(d));
    return combineLatest(requests);
  }

  delete(documentId: number): Observable<any> {
    return this.http.delete(`${this.authenticationRESTService.baseUrl()}/Documents/file/${documentId}`);
  }
}
