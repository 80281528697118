import { Directive, Input, Host, TemplateRef, ViewContainerRef, OnInit, DoCheck } from '@angular/core';
import { NgSwitch } from '@angular/common';

@Directive({
  selector: '[ngSwitchCases]'
})
export class SwitchCasesDirective implements OnInit, DoCheck {
  private ngSwitch: any;
  private _created = false;

  @Input()
  ngSwitchCases: NgSwitch[];

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    @Host() ngSwitch: NgSwitch
  ) {
    this.ngSwitch = ngSwitch;
  }

  ngOnInit(): void {
    (this.ngSwitchCases || []).forEach(() => this.ngSwitch._addCase());
  }

  ngDoCheck(): void {
    let enforce = false;
    (this.ngSwitchCases || []).forEach(value => enforce = this.ngSwitch._matchCase(value) || enforce);
    this.enforceState(enforce);
  }

  enforceState(created: boolean): void {
    if (created && !this._created) {
      this._created = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!created && this._created) {
      this._created = false;
      this.viewContainer.clear();
    }
  }
}
