import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IncidentFullObject } from '@app/incidents/models/incident.interface';
import { DateFormat } from '@app/shared/pipes/date-format.pipe';
import { FieldModel, FieldTypeEnum } from '@app/step-builder/models';
import { FormConfigType } from '@app/step-builder/models/form-config-type.enum';

@Component({
  selector: 'app-confirmation-step',
  templateUrl: './confirmation-step.component.html',
  styleUrls: ['./confirmation-step.component.less'],
})
export class ConfirmationStepComponent {
  @Input() field: FieldModel;
  @Input() formValues: IncidentFullObject = null;
  @Input() incident: IncidentFullObject = null;
  @Input() preSavedIncident: IncidentFullObject = null;
  @Input() configType: FormConfigType;
  @Input() isRapportPage: boolean;
  @Input() hideLabels: boolean = false;
  @Output() uploadFile: EventEmitter<any> = new EventEmitter();
  @Output() dropdownChanged: EventEmitter<any> = new EventEmitter();

  FieldType = FieldTypeEnum;
  dateFormat = DateFormat;
  configTypes = FormConfigType;
  constructor() {}

  upload(event): void {
    this.uploadFile.emit(event);
  }

  changed(event): void {
    this.dropdownChanged.emit(event);
  }
}
