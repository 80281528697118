import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.less'],
})
export class ErrorDialogComponent implements OnInit {
  error;
  constructor(private config: DynamicDialogConfig, public ref: DynamicDialogRef) {}

  ngOnInit(): void {
    this.get();
  }

  get keysFromObject(): string[] {
    return Object.keys(this.error?.errors || []);
  }

  get(): void {
    this.error = this.config?.data;
  }

  close(): void {
    this.ref.close();
  }
}
