import { Injectable } from '@angular/core';
import { combineLatest, Observable, tap } from 'rxjs';
import { WorkSpaceUser } from '@app/shared/models/user.interface';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';
import { WorkspaceRole } from '@app/admin/modules/management/modules/roles/models/role.model';
import { UsersRESTServiceInterface } from '@app/admin/modules/management/models/users-service.interface';
import { ListType } from '@app/shared/models/list-type.enum';

@Injectable({
  providedIn: 'root',
})
export class UsersRESTService implements UsersRESTServiceInterface {
  constructor(
    private http: HttpClient,
    private authenticationRESTService: AuthenticationRESTService
  ) {}

  get(searchString?: string, listType: ListType = ListType.All): Observable<WorkSpaceUser[]> {
    const queryParams: any = {};

    if (searchString) {
      queryParams.SearchString = searchString;
    }
    const queryParamString = new URLSearchParams(queryParams).toString();
    const api = `${this.authenticationRESTService.baseUrl()}/Admin/workspaceusers${
      listType == ListType.Trash ? '/trash' : ''
    }?${queryParamString}`;
    return this.http.get<any>(api);
  }

  getById(workspaceUserId: number): Observable<WorkSpaceUser> {
    const api = `${this.authenticationRESTService.baseUrl()}/Admin/workspaceusers/${workspaceUserId}`;
    return this.http.get<any>(api);
  }

  create(body: WorkSpaceUser): Observable<HttpEvent<WorkSpaceUser>> {
    const api = `${this.authenticationRESTService.baseUrl()}/Admin/workspaceusers`;
    return this.http.post<WorkSpaceUser>(api, body, {reportProgress: true, observe: 'events'});
  }

  update(workspaceUserId: number, body: WorkSpaceUser): Observable<HttpEvent<WorkSpaceUser>> {
    const api = `${this.authenticationRESTService.baseUrl()}/Admin/workspaceusers/${workspaceUserId}`;
    return this.http.put<any>(api, body, {reportProgress: true, observe: 'events'});
  }

  delete(workspaceUserId: number, softDelete: boolean = true): Observable<WorkSpaceUser> {
    const api = `${this.authenticationRESTService.baseUrl()}/Admin/workspaceusers/${workspaceUserId}`;
    return this.http.delete<WorkSpaceUser>(api, {
      params: {
        softDelete,
      },
    });
  }

  deleteUsers(
    users: WorkSpaceUser[],
    listType: ListType = ListType.All
  ): Observable<WorkSpaceUser[]> {
    const requests = users.map((r) => this.delete(r.workspaceUserId, listType !== ListType.Trash));
    return combineLatest<WorkSpaceUser[]>(requests);
  }

  untrashUsers(users: WorkSpaceUser[]): Observable<WorkSpaceUser[]> {
    const requests = users.map((r) => this.untrash(r.workspaceUserId, r));
    return combineLatest<WorkSpaceUser[]>(requests);
  }

  untrash(workspaceUserId: number, user: WorkSpaceUser): Observable<WorkSpaceUser> {
    const api = `${this.authenticationRESTService.baseUrl()}/Admin/workspaceusers/trash/${workspaceUserId}`;
    return this.http.put<WorkSpaceUser>(api, user);
  }

  getADUsers(searchString = ''): Observable<WorkSpaceUser[]> {
    const queryParams: any = {};

    if (searchString) {
      queryParams.SearchString = searchString;
    }
    const queryParamString = new URLSearchParams(queryParams).toString();

    const api = `${this.authenticationRESTService.baseUrl()}/Users/identity?${queryParamString}`;
    return this.http.get<WorkspaceRole[]>(api);
  }

  getUsers(): Observable<WorkSpaceUser[]> {
    const api = `${this.authenticationRESTService.baseUrl()}/Users`;
    return this.http.get<WorkspaceRole[]>(api);
  }
}
