import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';

@Component({
  selector: 'app-confirm-action-button',
  templateUrl: './confirm-action-button.component.html',
  styleUrls: ['./confirm-action-button.component.less'],
  providers: [TranslatePipe]
})
export class ConfirmActionButtonComponent implements OnInit {
  @Input() confirmModalTranslationKeys: {
    title: string;
    titleKey?: string;
    question?: string;
    confirm: string;
    decline: string;
  };
  @Input() disabled: boolean;
  @Input() buttonState: {
    translationKey?: string;
    text?: string;
    icon?: string;
    class?: string;
  } = {
    icon: 'fas fa-trash-alt',
      text: this.translatePipe.transform('ui.general.delete'),
    class: 'btn-delete',
  };
  @Output() onConfirm = new EventEmitter();
  @Output() onDecline = new EventEmitter();
  @Input() hidden: boolean;
  showDialog: boolean;
  constructor(
    private translatePipe: TranslatePipe
  ) {}

  ngOnInit(): void {}
  toggleModal(): void {
    this.showDialog = !this.showDialog;
  }

  confirm(): void {
    this.toggleModal();
    this.onConfirm.emit();
  }

  decline(): void {
    this.toggleModal();
    this.onDecline.emit();
  }
}
