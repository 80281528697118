export interface Breadcrumb {
  translation: string;
  url: string;
}

export enum BreadcrumbTranslations {
  //Audit
  Audit = 'Audit',
  AuditCreate = 'Create',
  AuditList = 'List',
  AuditEdit = 'Edit',
  AuditCreateInterview = 'Create Interview',
  AuditInterview = 'Interview List',
  AuditAnalysis = 'Analysis',
  InterviewEdit = 'Edit Interview',
  QuestionSet = 'Questionset',
  QuestionSetCreate = 'Create questionset',
  QuestionSetAnswers = 'Questionset answers',
  Answerset = 'Answerset',
  AnswersetCreate = 'Create answerset',
  AnswersetRead = 'Read answerset',
  AnswersetEdit = 'Edit answerset',
  //Incident
  Incidents = 'Incidents',
  IncidentCreate = 'Create Incident',
  IncidentEdit = 'Edit Incident',
  IncidentRead = 'Incident read',
  IncidentTasks = 'Incident tasks',
  IncidentLogs = 'Incident logs',
  IncidentMeasures = 'Incident measures',
  IncidentGraphics = 'Incident Graphics',
  IncidentAnalysis = 'Incident analysis',
  //Settings
  SettingsUserInfo = 'Users info',
  SettingsNotifications = 'Notifications',
  SettingsSecurity = 'Security',
  SettingsLanguage = 'Language',

  Organizations = 'Organizations',

  Overview = 'Overview',

  Tasks = 'Tasks',
  TaskCreate = 'Create task',
  TaskEdit = 'Edit task',

  Admin = 'Admin',

  Helper = 'Helper',
  //General
  Home = '🏠',
  Help = 'Help',
  About = 'About',
  Params = '%params%',

}
