import { Directive, Input, OnInit } from '@angular/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ClientStorageService } from '../services/client-storage.service';

@Directive({
  selector: '[pTableOnRowsChange]',
})
export class pTableOnRowsChange implements OnInit {
  @Input()
  pTableOnRowsChange: any;

  constructor(private storageService: ClientStorageService) {}

  ngOnInit(): void {
    if (this.pTableOnRowsChange?._rows) {
      this.pTableOnRowsChange._rows =
        Number(this.storageService.getValue(this.storageService.KEYS.TABLE_ROWS)) || 10;
      const storageFirstValue = Number(this.storageService.getSessionValue(this.storageService.KEYS.TABLE_FIRST)) || 0;
      if (this.pTableOnRowsChange._totalRecords > storageFirstValue) {
        this.pTableOnRowsChange._first =
          Number(this.storageService.getSessionValue(this.storageService.KEYS.TABLE_FIRST)) || 0;
      } else {
        this.pTableOnRowsChange._first = 
          this.pTableOnRowsChange._totalRecords - (this.pTableOnRowsChange._totalRecords % 10);
      }
    }
    this.pTableOnRowsChange?.onPage
      ?.pipe(
        switchMap((val: any) => {
          this.storageService.setSessionValue(this.storageService.KEYS.TABLE_FIRST, val?.first);
          return this.pTableOnRowsChange?.rowsChange;
        })
      )
      .subscribe({
        next: (val) => this.storageService.setValue(this.storageService.KEYS.TABLE_ROWS, val),
      });
  }
}
