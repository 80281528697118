import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomRouteReuseStrategy } from '@app/shared/services/custom-route-reuse-strategy.sevice';
import { SafePipe } from '@app/file-editor/pipes/safe.pipe';
import { TopNavComponent } from './shell/top-nav/top-nav.component';
import { FileEditorComponent } from './file-editor/components/view/file-editor.component';
import { LogInterceptor } from './shared/interceptors/log.interceptor';
import { ApiInterceptor } from './shared/interceptors/api.interceptor';
import { LayoutComponent } from './shell/layout.component';
import { DocumentsModule } from './documents/documents.module';
import { AuthenticationService } from './auth/services/authentication.service';
import { APIUrlGuard } from './auth/guards/api.guard';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';

// Prime NG
import { ProgressBarModule } from 'primeng/progressbar';
import { MenubarModule } from 'primeng/menubar';
import '@angular/common/locales/global/sv';
import { UserInactivityDirective } from './shell/directives/user-inactivity.directive';
import { NotFoundComponent } from './shell/not-found/not-found.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ServiceWorkerModule } from '@angular/service-worker';
//import { environment } from '../environments/environment';
import { ApiErrorComponent } from './shell/api-error/api-error.component';

@NgModule({
  declarations: [
    AppComponent,
    TopNavComponent,
    LayoutComponent,
    FileEditorComponent,
    SafePipe,
    UserInactivityDirective,
    NotFoundComponent,
    ApiErrorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule,
    ProgressBarModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    FormsModule,
    MenubarModule,
    DocumentsModule,
    NgxDocViewerModule,
    ServiceWorkerModule.register('custom-service-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      deps: [AuthenticationService], // some service handling global settings
      useFactory: (authService) => authService.getLanguageCode(), // returns locale string.
    },
    DialogService,
    APIUrlGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
