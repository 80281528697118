import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarProperties } from '@app/shared/components/progress-bar/models/progress-bar.interface';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.less']
})
export class ProgressBarComponent implements OnInit{
  @Input() barProperties: ProgressBarProperties = {
    borderColor: 'black',
    borderType: 'solid',
    borderSize: 1,
    backgroundColor: '#D3D3D3',
    height: 12
  };
  @Input() showPercent: boolean = true;

  ngOnInit(): void {
    this.barProperties = {
      borderColor: 'black',
      borderType: 'solid',
      borderSize: 1,
      backgroundColor: '#D3D3D3',
      height: 12,
      ...this.barProperties
    };
  }
}
