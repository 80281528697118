import * as apiIsap from '../assets/api.json';

let api = apiIsap;

export const environment = {
  //production: false,
  //application_name: 'ISAP',
  //images_path: '../assets/images/',
  //logo_filename: 'logo.png',
  apiUrl: api.API_URL,
  securityCode: 'U2FsdGVkX18gUWNXZbgijVVTKQDYupsHFIr+JCz24xihEjkGFaMCzokMug564os6',
  //urlEncoded: 'application/x-www-form-urlencoded',
  //applicationJson: 'application/json',
  //isApplicationOffline: false,
};
