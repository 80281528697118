import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { TreeNode, MessageService } from 'primeng/api';
import { FormControl, FormGroup } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { map, takeUntil } from 'rxjs/operators';

import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { MultiStepsRESTService } from '@app/step-builder/services/rest-services/multi-steps.services';
import { QuestionnairesRESTService } from '@app/audit/modules/questionnaires/services/rest-services/questionnaires.service';
import { Type } from '@app/audit/modules/questionnaires/models/questionnaries.interface';
import { getSelectedNodeName } from './../../utils/find-tree-node.recursion';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';
import { OptionsTypeEnum } from '@app/step-builder/models/options-type.enum';
import { FieldTypeEnum } from '@app/step-builder/models';
import { setDisabledType } from '@app/shared/utils/add-key-value-tree-node';
import { IsapTreeNode } from '@app/shared/models/isap-tree-node.interface';
import { flatten } from '@app/shared/utils/flatten-tree';

@Component({
  selector: 'app-tree-picker',
  templateUrl: './tree-picker.component.html',
  styleUrls: ['./tree-picker.component.less'],
})
export class TreePickerComponent extends UnsubscriberComponent implements OnInit, OnChanges {
  @Input() field: any;
  @Input() form: FormGroup;
  @Input() options;
  @Input() fieldType: FieldTypeEnum = FieldTypeEnum.Radiobutton;
  @Input() recentlyReportedLocations: any[];
  @Input() controlForDisplay: FormControl = new FormControl();
  @Input() parentClass: string = 'tree-picker-parent';
  @Input() canDelete: boolean = false;

  @ViewChild('myDialog') dialog: OverlayPanel;
  public selectedValue: TreeNode;
  public loading: boolean = false;
  public fieldTypeEnum = FieldTypeEnum;
  public optionsTypeEnum = OptionsTypeEnum;
  public flatChildren: unknown[];

  constructor(
    private messageService: MessageService,
    private multiStepsRESTService: MultiStepsRESTService,
    private questionnaireService: QuestionnairesRESTService,
    private translate: TranslatePipe
  ) {
    super();
  }
  clearSelectedValue(): void {
    this.selectedValue = {};
    if (this.form?.get(this.field.property.replace('Id', ''))) {
      this.form.get(this.field.property.replace('Id', '')).reset();
    }
    this.controlForDisplay.reset();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.recentlyReportedLocations?.currentValue) {
      this.recentlyReportedLocations =
        this.recentlyReportedLocations.map((l) => this.mapLocation(l)) || [];
    }
    if (changes.controlForDisplay && !!this.options?.length) {
      this.selectedValue = getSelectedNodeName(this.options, this.controlForDisplay.value);
    }
  }

  ngOnInit(): void {
    if (!this.options?.length) {
      (this.field || {}).options = [];
      this.options = (this.field || {}).options;
      this.getOptions();
    } else {
      this.options = setDisabledType(this.options);
      this.selectedValue = getSelectedNodeName(this.options, this.controlForDisplay.value);
    }

    this.controlForDisplay.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((value) => {
      if (!!value) {
        this.selectedValue = getSelectedNodeName(this.options, value);
      } else {
        this.selectedValue = null;
      }
    });
  }

  public nodeSelect($event: {originalEvent: PointerEvent, node: IsapTreeNode} | PointerEvent): void {
    if ('node' in $event && $event.node.disabled) {
      $event.node.expanded = false;
      return;
    }
    let originalEvent: PointerEvent;
    if('originalEvent' in $event){
      originalEvent = $event.originalEvent;
    }else{
      originalEvent = $event;
    }
    originalEvent.stopImmediatePropagation();
    this.messageService.add({
      severity: 'info',
      summary: this.translate.transform('ui.messages.incident.location.selected'),
      detail: this.selectedValue?.label,
    });
    this.controlForDisplay.patchValue(this.selectedValue?.data);
    const val = {
      id: this.selectedValue?.data,
      name: this.selectedValue?.label,
    };
    if (this.form) {
      if (this.form?.get(this.field.property.replace('Id', ''))) {
        this.form.get(this.field.property.replace('Id', '')).patchValue(val, {emitEvent: false});
      } else {
        this.form.addControl(this.field.property.replace('Id', ''), new FormControl(val));
      }
    }
    this.toggleDialog(originalEvent);
  }

  toggleDialog($event: MouseEvent | Event | PointerEvent): void {
    if (!($event as PointerEvent).x && !($event as PointerEvent).y) {
      return;
    }
    if (this.dialog.overlayVisible) {
      this.dialog.hide();
    } else {
      this.dialog.show($event);
    }
  }

  expandNode(e): void {
    e.node.expanded = !Boolean(!!e.node.expanded);
  }

  getOptions(): void {
    if (!this.field) {
      return;
    }
    this.loading = true;
    this.multiStepsRESTService
      .getOptionsByUrl(this.field.optionsUrl)
      .pipe(
        takeUntil(this.$destroy),
        map((response) => {
          if (this.field.optionsUrl === Type.QuestionSet + 's') {
            return {
              array: this.questionnaireService.mapQuestionnaire(response, 'id').map((element) => {
                return this.mapLocationQuestionnaire(element);
              }),
            };
          }
          return response;
        })
      )
      .subscribe((results: any) => {
        this.recentlyReportedLocations =
          results?.response?.recentlyReportedLocations.map((l) => this.mapLocation(l)) || [];
        this.options.length = 0;
        this.options.push(...(results.array || []));
        this.mapDisabledOptions(results?.response?.children, this.options);
        this.loading = false;
        if (this.options?.length) {
          this.options = setDisabledType(this.options);
          this.selectedValue = getSelectedNodeName(results.array, this.controlForDisplay.value);
        }
      });
  }

  private mapDisabledOptions(children: { id: number, disabled: boolean }[], options: IsapTreeNode[]): void {
     
    const extractChildren = (x) => x?.children || [];
    const flattenOptions = flatten('id', children, extractChildren)?.map(
      (x) => delete x.children && x
    );
    const setDisabledState = (optionsArray: IsapTreeNode[]) => {
      optionsArray.forEach((option: IsapTreeNode) => {
        const checkedChild = flattenOptions.find((child: { id: number, disabled: boolean }) => child.id === option.data);
        option.disabled = checkedChild.disabled;
        if (!!option?.children && !!option?.children?.length) {
          setDisabledState(option.children);
        }
      });
    }
    setDisabledState(options);
  }

  private mapLocation(location) {
    return {
      data: location.id,
      label: location.name,
    };
  }

  private mapLocationQuestionnaire(location) {
    const input = {
      data: location.id,
      label: location.name,
      children: location.children.map((item) => this.mapLocationQuestionnaire(item)),
    };
    return input;
  }
}
