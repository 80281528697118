import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersRESTService } from '@app/admin/modules/management/services/rest-services/users.service';
import { MessageModel, MessageTemplateModel } from '@app/audit/models/audit.interface';
import { CreatedBy, Interview, Interviews } from '@app/audit/modules/interview/models/interview.interface';
import { AuditsRESTService } from '@app/audit/services/rest-services/audits.service';
import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { UserSimplified, WorkSpaceUser } from '@app/shared/models/user.interface';
import { take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent extends UnsubscriberComponent implements OnInit {
  @Input() selectedInterviews: Interviews = [];
  @Input() auditId?: number;
  @Input() showMessageDialog: boolean;
  @Output() close = new EventEmitter<void>();
  @Output() onSubmit = new EventEmitter<MessageModel>();
  form: FormGroup;
  templates: MessageTemplateModel[] = [];
  selectedTemplate: MessageTemplateModel;
  selectedUser: WorkSpaceUser;
  selectedUsers: UserSimplified[] = [];
  selectedIds: number[] = [];
  users: WorkSpaceUser[] = [];

  constructor(private usersRESTService: UsersRESTService,
              private fb: FormBuilder,
              private auditRESTService: AuditsRESTService,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.search();
    this.getTemplates();
    this.form = this.fb.group({
      emailSubject: ['', Validators.required],
      emailBody: ['', Validators.required],
    });
    if (this.selectedInterviews?.length) {
      this.handleDefaultRecipients();
    }
  }

  search(searchEvent: { query: string } = { query: '' }): void {
    this.usersRESTService.get(searchEvent.query)
    .pipe(takeUntil(this.$destroy))
    .subscribe((users: WorkSpaceUser[]) => {
      this.users = users;
      this.cdr.markForCheck();
    });
  }

  handleUserSelection(user: WorkSpaceUser | CreatedBy): void {
    this.selectedUser = undefined;
    const userExists = !!this.selectedUsers
      .find((checkedUser) => checkedUser.id === user.workspaceUserId);
    if (!userExists && user.email) {
      this.selectedUsers.push({ id: user.workspaceUserId, email: user.email });
    }
    this.cdr.markForCheck();
  } 

  handleTemplateSelection(selectionEvent: { value: MessageTemplateModel }): void {
    this.form.patchValue({ 
      emailSubject: selectionEvent.value.messageSubject, 
      emailBody: selectionEvent.value.messageBody,
    });
    this.cdr.markForCheck();
  }

  deleteEmail(id: number): void {
    this.selectedUsers = this.selectedUsers.filter((user: UserSimplified) => {
      return id !== user.id;
    }); 
  }

  emitDialogClose(): void {
    this.resetData();
    this.close.emit();
  }

  emitSubmition(): void {
    const workspaceUserIds = this.selectedUsers.map((user) => user.id);
    const emails = this.selectedUsers.map((user) => user.email);
    const submitionObject: MessageModel = {
      emails,
      workspaceUserIds,
      title: this.form.value.emailSubject,
      body: this.form.value.emailBody,
    }
    this.onSubmit.emit(submitionObject);
    this.emitDialogClose();
  }

  updateFormValidity(): void {
    this.form.updateValueAndValidity();
  }

  private handleDefaultRecipients(): void {
    const recipients = this.selectedInterviews
      .map((interview: Interview) => interview.interviewSubject);
    recipients.forEach((recipient: CreatedBy) => {
      if (!!recipient) {
        this.handleUserSelection(recipient);
      }
    });
  }

  private getTemplates(): void {
    this.auditRESTService.getMessageTemplates(this.auditId)
    .pipe(take(1))
    .subscribe((templates: MessageTemplateModel[]) => {
      this.templates = templates;
      this.cdr.markForCheck();
    });
  }

  private resetData(): void {
    this.form.reset();
    this.selectedUsers = [];
    this.selectedTemplate = undefined;
    this.selectedUser  = undefined 
    this.selectedUsers = []
    this.selectedIds = []
  }
}
