import { AuthenticationService } from '@app/auth/services/authentication.service';
import { Injectable } from '@angular/core';
import { CanLoad, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';

@Injectable()
export class APIUrlGuard implements CanActivate {
  constructor(private auth: AuthenticationService,
              private authenticationRESTService: AuthenticationRESTService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationRESTService.getDynamicApiUrl().pipe(
      map(({ API_URL }: { API_URL: string }) => {
        if (API_URL) {
          this.authenticationRESTService.env = API_URL;
          return true;
        }

        alert('CONTACT YOUR ADMINISTRATOR, no server api link in assets!');
        return false;
      })
    );
  }
}
