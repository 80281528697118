import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appAutoresize]'
})
export class AutoresizeDirective implements OnInit{
  private input: HTMLTextAreaElement;
  private readonly avgLineHeight = 22;

  constructor(private element: ElementRef) {
    this.input = this.element.nativeElement;
  }
  ngOnInit(): void {
    this.adjustRowsNumber();
  }

  @HostListener("input")
  onInput(): void {
    this.adjustRowsNumber();
  }

  private adjustRowsNumber(): void {
    if(this.input.scrollHeight > this.input.clientHeight){
      const previousNumberOfRows = Math.ceil(this.input.clientHeight / this.avgLineHeight)
      const overflowedRows = Math.ceil((this.input.scrollHeight - this.input.clientHeight) / this.avgLineHeight);
      this.input.rows = previousNumberOfRows + overflowedRows;
    }
  }

}
