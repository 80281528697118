import { AfterViewInit, OnChanges, SimpleChanges, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import '@github/markdown-toolbar-element';
import * as marked from 'marked';

enum ModesEnum {
  PreviewMode = 'preview',
  EditorMode = 'editor'
}

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.less']
})
export class TextEditorComponent implements AfterViewInit, OnChanges {
  @Input() controlName: string;
  @Input() form: FormGroup;
  @Input () markdownHelp: boolean;
  markdownHTML = '';
  modes = ModesEnum;
  mode: ModesEnum = ModesEnum.EditorMode;
  showMarkdownHelp: boolean;
  constructor() { }

  ngAfterViewInit(): void {
    this.updateMarkdownPreview();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMarkdownPreview();
  }

  updateMarkdownPreview(): void {
    this.markdownHTML = marked.parse(!!this.form.value[this.controlName] ? this.form.value[this.controlName] : '', {mangle: false, headerIds: false})
      .replace(/\r?\n/g, '<br />');
  }

  changeMode(mode: ModesEnum): void {
    this.mode = mode;
  }

  toggleMarkdownHelp(): void {
    this.showMarkdownHelp = !this.showMarkdownHelp;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }


  onDrop(event: DragEvent): void {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain');
    const textarea = event.target as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const text = textarea.value;

    textarea.value = text.substring(0, start) + data + text.substring(end);
    this.form.controls[this.controlName].setValue(textarea.value);
    this.updateMarkdownPreview();

    // Optional: Reset cursor position after drop
    textarea.setSelectionRange(start + data.length, start + data.length);
    textarea.focus();
  }
}
