// TESTAR EN GAMMAL KOMPONENT FRÅN DET ANDRA PROJEKTET
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LoaderServiceInterface } from '@app/shell/models/loader-service.interface';

@Injectable({ providedIn: 'root' })

export class LoaderService implements LoaderServiceInterface{
  isLoading = new Subject<boolean>();
  show(): void {
    this.isLoading.next(true);
  }
  hide(): void {
    this.isLoading.next(false);
  }
}
