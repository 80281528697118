import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.less']
})
export class ConfirmationComponent extends UnsubscriberComponent implements OnChanges {
  @Input() translationKeys: {
    title: string;
    titleKey?: string;
    question?: string;
    questionKey?: string;
    confirm: string;
    decline: string;
  };
  @Input() IncidentNumber;
  @Output() onConfirm = new EventEmitter();
  @Output() onDecline = new EventEmitter();
  label;

  constructor(private translatePipe: TranslatePipe) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.IncidentNumber) {
      this.label = this.getTranslatedQuestion().replace(/{IncidentNumber}/, `${this.IncidentNumber}`).replace('$', '');
    } else {
      this.label = this.getTranslatedQuestion();
    }
  }

  getTranslatedQuestion(): string {
    return this.translatePipe
      .transform(this.translationKeys?.question, this.translationKeys?.questionKey);
  }

  confirm(): void {
    this.onConfirm.emit();
  }

  decline(): void {
    this.onDecline.emit();
  }
}
