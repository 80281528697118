import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';

export enum DateFormat {
  dateTime = 'dateTime',
  date = 'date',
  time = 'time',
}

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(private authService: AuthenticationService) {}

  transform(date: string | Date, format: DateFormat = DateFormat.date, code?: string): string {
    if (!date) {
      return '';
    }
    const languageCode = this.authService.getLanguageCode();
    const newDate = new Date(date);

    const utcFormat = Date.UTC(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newDate.getHours(),
      newDate.getMinutes(),
      newDate.getSeconds()
    );
    const localDate =
      typeof date == 'string' && !date?.includes('Z') ? new Date(utcFormat) : newDate;
    const option = getDateFormat(format);
    return localDate.toLocaleString(code || languageCode, option as any);
  }

  formatToSimpleDate(dateString: string): string {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}

function getDateFormat(format: DateFormat) {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  switch (format) {
    case DateFormat.date:
      return { timeZone: localTimeZone, dateStyle: 'short' };
    case DateFormat.dateTime:
      return { timeZone: localTimeZone, dateStyle: 'short', timeStyle: 'short' };
    case DateFormat.time:
      return { timeZone: localTimeZone, timeStyle: 'short' };
    default:
      return { timeZone: localTimeZone, dateStyle: 'short', timeStyle: 'short' };
  }
}
