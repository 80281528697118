import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoaderService } from '@app/shell/services/loader.service';
import { ErrorMessage } from '@app/shared/models/error-message.interface';
import { IsapToasterService } from '@app/shared/components/isap-toaster/service/isap-toaster.service';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoaderService, private isapToaster: IsapToasterService) {}

  private _enableLogging: boolean = false;

  // Generate a identifier for identifying logs on async calls
  generateId() {
    return Math.random().toString().substr(2, 9);
  }

  log(item: any) {
    if (this._enableLogging) console.log(item);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //TODO: Display this in GUI

    const requestId = this.generateId();

    this.log({
      Log: 'Outgoing API Request',
      Method: request.method,
      RequestId: requestId,
      request,
    });
    this.loadingService.show();

    // Process next interceptor(s) in chain
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.log({
            Log: 'Incoming API Response',
            Method: request.method,
            RequestId: requestId,
            event,
          });
          this.loadingService.hide();
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleError(error.error);
        this.log({
          Log: 'Failed API Response',
          Method: request.method,
          RequestId: requestId,
          Error: error.message,
        });

        this.loadingService.hide();

        return throwError(error);
      })
    );
  }

  private handleError(message: ErrorMessage) {
    if (!message?.displayClientSide) {
      return;
    }
    this.isapToaster.$panelMessage.next(message);
  }
}
