import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorMessage } from '@app/shared/models/error-message.interface';

@Injectable({
  providedIn: 'root',
})
export class IsapToasterService {
  $panelMessage = new BehaviorSubject<ErrorMessage>(null);

  constructor() {}

  clear() {
    this.$panelMessage.next(null);
  }
}
