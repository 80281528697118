export enum LocationKey {
  Role = 'role',
  Roles = 'roles',
  Privileges = 'privileges',
  UserSettings = 'usersettings',
  User = 'user',
  Users = 'users',
  Seesions = 'sessions',
  Worklist = 'worklist',
  Logout = 'logout',

  Admin = 'admin',
  AdminAudit = 'admin_audit',
  AdminOrganization = 'admin_object',
  AdminObjectClassificationTypes = 'admin_object_classificationtypes',
  AdminIncident = 'admin_incident',
  AdminIncidentWorkLists = 'admin_incident_worklists',
  AdminNotifications = 'admin_notifications',
  AdminPrivileges = 'admin_privileges',
  AdminPrivilegesUsers = 'admin_privileges_users',
  AdminPrivilegesRoles = 'admin_privileges_roles',
  AdminPrivilegesSessions = 'admin_privileges_sessions',
  AdminTrashcan = 'admin_trashcan',
  AdminTrashcanUsers = 'admin_trashcan_users',
  AdminTrashcanRoles = 'admin_trashcan_roles',
  AdminIncidentStatuses = 'admin_incident_statuses',
  AdminIncidentChecklists = 'admin_incident_checklists',
  AdminRisk = 'admin_risk',
  AdminTask = 'admin_task',
  AdminTaskStatuses = 'admin_task_statuses',
  AdminSystem = 'admin_system',
  AdminWorkspace = 'admin_workspace',
  AdminTextelements = 'admin_textelements',

  Audit = 'audit',
  AuditAuditlist = 'audit_auditlist',
  AuditAuditlistDetails = 'audit_auditlist_details',
  AuditAuditlistAnalysis = 'audit_auditlist_analysis',
  AuditCreate = 'audit_create',
  AuditCreateInterview = 'audit_create_interview',
  AuditMyassessments = 'audit_myassessments',
  AuditMyinterviews = 'audit_myinterviews',
  AuditMysurveys = 'audit_mysurveys',
  AuditQuestionset = 'audit_questionset',
  AuditQuestionSetDetails = 'audit_questionset_details',

  Incident = 'incident',
  IncidentList = 'incidentlist',
  IncidentAnalysis = 'incident_analysis',
  IncidentCreate = 'incident_create',
  IncidentDraft = 'incident_draft',
  IncidentInbox = 'incident_inbox',
  IncidentOutbox = 'incident_outbox',
  IncidentSent = 'incident_sent',
  IncidentTrash = 'incident_trash',

  Organization = 'organization',
  OrganizationAudits = 'organization_audits',
  OrganizationIncidents = 'organization_incidents',
  OrganizationInformation = 'organization_information',
  OrganizationRisks = 'organization_risks',
  OrganizationUsers = 'organization_users',

  Task = 'task',
  TaskCreate = 'task_create',
  TaskInbox = 'task_inbox',
  TaskOutbox = 'task_outbox',
  TaskTrash = 'task_trash',
}
