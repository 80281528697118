import { AuthenticationService } from '@app/auth/services/authentication.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';
import { OptionsTypeEnum } from '@app/step-builder/models/options-type.enum';
import { FieldOption, FormConfig } from '@app/step-builder/models';
import { FormConfigType } from '@app/step-builder/models/form-config-type.enum';
import { MultiStepsService } from '@app/step-builder/services/multi-steps.service';
import { MultiStepsRESTServiceInterface } from '@app/step-builder/models/multi-steps-service.interface';

@Injectable({
  providedIn: 'root',
})
export class MultiStepsRESTService implements MultiStepsRESTServiceInterface {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private authenticationRESTService: AuthenticationRESTService,
    private multiStepsService: MultiStepsService
  ) {}

  getOptions(type: OptionsTypeEnum): Observable<FieldOption[]> {
    return this.http
      .get<FieldOption[]>(
        `${this.authenticationRESTService.baseUrl()}/Configuration/Classification/Types/${type}`
      )
      .pipe(map((response) => response));
  }

  // Get the prerequisits for a showing and incident form
  getFormConfig(formConfigType = FormConfigType.New, params = {}, entityId = null) {
    const paramsString =
      formConfigType !== FormConfigType.New ? new URLSearchParams(params).toString() : '';
    let api = `${this.authenticationRESTService.baseUrl()}/Incidents/${formConfigType}?${paramsString}`;

    if (
      (formConfigType === FormConfigType.Edit || formConfigType === FormConfigType.Read) &&
      Number.isInteger(entityId)
    ) {
      api = `${this.authenticationRESTService.baseUrl()}/Incidents/${entityId}/${formConfigType}?${paramsString}`;
    }
    return this.http.get<any>(api).pipe(map((resp) => resp));
  }

  getOptionsByUrl(optionsUrl: string, params: any = null, search: string = '') {
    if (!params) {
      params = {};
    }
    if (search) {
      params.SearchString = search;
    }
    params.LanguageId = this.authService.getLanguageId();
    const url = optionsUrl.split('?')[0];
    return this.http
      .get<FieldOption[]>(`${this.authenticationRESTService.baseUrl()}/${url}`, {
        params,
      })
      .pipe(map((results: Array<any>) => this.multiStepsService.mapResults(results, url)));
  }
}
