import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { MenuItem } from 'primeng/api';
import { LocationKey } from '@app/shared/models/location-key.enum';
import { NavigationItems } from '@app/shared/models/user.interface';
import { findTreeNodeRecursion } from '@app/shared/utils/find-tree-node.recursion';
import { LessService } from '@app/shared/services/less.service';
import { ActivatedRoute, NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.less'],
})
export class TabsComponent extends UnsubscriberComponent implements OnInit {
  @Input() locationKey: LocationKey;
  @Input() subLocationKey: LocationKey | boolean;
  @Input() locations;
  @Input() queryParamsHandling: string;
  @Input() numberedTab: boolean;
  @Input() translationKey: string = 'translations';
  @Input() backgroundColor: string;
  @Input() padding: string;
  @Input() transparentList: boolean;
  @Input() disabledSteps: number[];
  @Input() emptyRouterLink: boolean;
  @Input() additionalStatus: boolean = false;
  selectedOption: MenuItem;
  isRedirecting: boolean = false;
  tabs: MenuItem[];
  private innitialRoute: string;

  constructor(private router: Router,
              private authenticationservice: AuthenticationService, 
              private lessService: LessService,
              private route: ActivatedRoute) {
                super();
              }

  ngOnInit(): void {
    if (this.locations) {
      if (this.locationKey) {
        this.tabs = this.transformModulesToTabs(this.locations);
      } else {
        this.tabs = this.locations;
      }
    } else {
      this.tabs = this.tabItems;
    }
    this.selectedOption = this.tabs.find((tab: MenuItem) => {
      return this.router.url.includes(tab.routerLink);
    });
    this.innitialRoute = this.router.url;
  }

  get tabItems(): MenuItem[] {
    const user = this.authenticationservice.getUserProfile();
    if (user) {
      const loc = user.navigation.items.find((a) => a.locationKey === this.locationKey);
      if (!!this.subLocationKey) {
        const subLoc = findTreeNodeRecursion(
          loc,
          this.subLocationKey,
          'locationKey',
          'items'
        ) as any;
        return this.transformModulesToTabs(subLoc?.items);
      } else {
        return this.transformModulesToTabs(loc?.items);
      }
    } else {
      return [];
    }
  }

  navigate(route: string | { outlets: { detailInfo: string } }, event: MouseEvent): void {
    const newRoute = (typeof route === 'string') ? route : route.outlets?.detailInfo;
    if (!this.innitialRoute.includes(newRoute)) {
      if (!event.ctrlKey) 
        this.isRedirecting = true;
      this.router.events.pipe(
        takeUntil(this.$destroy),
        filter((event) => event instanceof NavigationEnd || event instanceof NavigationSkipped)
      ).subscribe(() => {
        this.innitialRoute = this.router.url;
        this.isRedirecting = false;
      });
    }
  }

  transformModulesToTabs(_modules: NavigationItems[]): MenuItem[] {
    return (_modules || []).map((m) => ({
      label: m.title,
      icon: m.icon,
      routerLink: this.routeMe(m.locationKey),
      locationKey: m?.locationKey,
      items: m?.items,
    }));
  }

  routeMe(route: string): any {
    if (!route) {
      return;
    }
    switch (this.locationKey) {
      case LocationKey.Organization:
        switch (route) {
          case 'incident_trash':
            return 'trash';
          case 'incident_analisys':
            return 'analysis';
          default:
            return this.getRouteNameFromLocation(route);
        }
      case LocationKey.Audit:
        switch (route) {
          case 'audit_questionset_details': 
            return 'all';
          case 'audit_questionset_answersets':
            return 'answers';
          case 'audit_auditlist_details':
            return { outlets: { detailInfo: 'view' } };
          case 'audit_auditlist_interviews':
            return { outlets: { detailInfo: 'interview' } };
          case 'audit_auditlist_analysis':
            return { outlets: { detailInfo: 'analysis' } };
          default:
            return this.getRouteNameFromLocation(route);
        }
      case LocationKey.Incident:
        switch (route) {
          case 'incident_sent':
            return 'outbox';
          case 'incident_draft':
            return 'drafts';
          case 'incident':
            return 'incidents';
          default:
            return this.getRouteNameFromLocation(route);
        }
      case LocationKey.Admin:
        switch (route) {
          case 'admin_incident_area_category_detail':
            return 'acd';
          case LocationKey.AdminTrashcan:
            return 'privileges/trash';
          case LocationKey.AdminPrivileges:
            return 'privileges/all';
          default:
            return this.getRouteNameFromLocation(route);
        }
      default:
        return this.getRouteNameFromLocation(route);
    }
  }

  getRouteNameFromLocation(route): string {
    const path = (this.subLocationKey || this.locationKey) + '_';
    return route.replace(path, '');
  }
}
