export function parseData(controlValue: any): any {
  if(controlValue === 'true' ) {
    return true;
  } 
  if(controlValue === 'false') {
    return false;
  }
  if(controlValue === true || controlValue === false){
    return controlValue;
  }
  let mappedValue: Date | Array<number> | number;
  const isArrayValue = Array.isArray(controlValue) && controlValue.length
  const isDateValue = !isNaN(new Date(controlValue).getMilliseconds());
  const isNumberValue = !isNaN(Number(controlValue));
  if (isDateValue) {
    mappedValue = new Date(controlValue);
  }
  if (isNumberValue) {
    mappedValue = Number(controlValue);
  }
  if (isArrayValue) {
    mappedValue = (controlValue as string[]).map(Number);
  }
  return !!mappedValue ? mappedValue : controlValue;
}