import { UserProfile } from './shared/models/user.interface';
import { AuthenticationService } from './auth/services/authentication.service';
import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { LoaderService } from './shell/services/loader.service';
import { Subject, fromEvent } from 'rxjs';
import * as translationsJSON from 'src/assets/translations/primeng-components.json';
import { PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { PwaService } from './shared/services/pwa.service';
import { SwUpdate } from '@angular/service-worker';
import { UnsubscriberComponent } from './shared/helpers/unsubscriber/unsubscriber.component';
import { takeUntil } from 'rxjs/operators';
import { LessService } from './shared/services/less.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent extends UnsubscriberComponent implements AfterContentChecked, OnInit {
  title = 'Isap';
  showTopNav: boolean;

  // PROPERTIES
  public userprofile: UserProfile;
  public isLoading: Subject<boolean> = this.loadingSerivce.isLoading;
  public internetConnection = window.navigator.onLine;
  private allowStandAlone: boolean = false;

  // CONSTRUCTOR
  constructor(
    private loadingSerivce: LoaderService,
    private authenticationService: AuthenticationService,
    private config: PrimeNGConfig,
    private titleService: Title,
    private http: HttpClient,
    lessService: LessService,
    private pwaService: PwaService,
    private readonly swUpdate: SwUpdate,
  ) {
    super();
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.pwaService.setBeforeInstallPromptEventData = event;
    });
    if (this.swUpdate.isEnabled) {
      this.checkForUpdate();
    }
  }
  // APP COMPONENT INIT
  ngOnInit(): void {
    this.setPrimeNgTranslations();
    this.setTitle();
    fromEvent(window, 'offline').pipe(takeUntil(this.$destroy))
    .subscribe(() => this.internetConnection = false);
  }

  // APP COMPONENT AFTERCONTENTCHECKED
  ngAfterContentChecked(): void {
    this.authenticationService.isAuthenticated.observe
      .subscribe((isAuthenticated: boolean) => this.showTopNav = isAuthenticated);
  }

  public displayOfflineWarning(): boolean {
    let allowConnection = this.allowStandAlone || this.internetConnection;
    return !allowConnection;
  }

  public setTitle() {
    const cacheBusting = new Date().getTime();
    const api = `assets/api.json?v=${cacheBusting}`;
    this.http.get(api).subscribe((data: { ISAP_TITLE, STANDALONE }) => {
      if (data.ISAP_TITLE) this.titleService.setTitle(data.ISAP_TITLE);
      if (data.STANDALONE) this.allowStandAlone = data.STANDALONE;
    });
  }
  private setPrimeNgTranslations(): void {
    const translations = translationsJSON;
    const language = this.authenticationService.getLanguageCode() || 'en';
    this.config.setTranslation(translations['default'][language]);
  }

  private checkForUpdate(): void {
    const updateVersion = () => {
      if (confirm('New version available. Update now?')) {
        this.swUpdate.activateUpdate().then(() => {
          window.location.reload();
        });
      }
    }
    this.swUpdate.checkForUpdate()
    .then((isNewVersion: boolean) => {
      if (isNewVersion) {
        updateVersion();
      } 
    })
  }
}
