import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { IncidentFullObject } from '@app/incidents/models/incident.interface';
import { StepBuilderInterface, FieldModel, FieldTypeEnum } from '@app/step-builder/models';
import { DocumentsService } from '@app/documents/services/documents.service';
import { ActivatedRoute } from '@angular/router';
import { MultiStepsService } from '@app/step-builder/services/multi-steps.service';
import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { map, takeUntil } from 'rxjs/operators';
import { FormConfigType } from '@app/step-builder/models/form-config-type.enum';
import { Location } from '@angular/common';
import { MultiStepsRESTService } from '@app/step-builder/services/rest-services/multi-steps.services';
import { DateFormatPipe, DateFormat } from '../../pipes/date-format.pipe';

@Component({
  selector: 'app-incident-preview',
  templateUrl: './incident-preview.component.html',
  styleUrls: ['./incident-preview.component.less'],
  providers: [DateFormatPipe],
})
export class IncidentPreviewComponent extends UnsubscriberComponent implements OnInit, OnChanges {
  @Input() back: { isInsideModal: boolean; view: 'bottom' | 'top' };
  @Input() incident: IncidentFullObject;
  @Input() isInsideModal: boolean;
  @Input() isBackAction: boolean;
  @Input() checkboxView: boolean;
  @Input() selectedOnly: boolean = false;
  @Input() selectedValues = [];
  @Input() configType = FormConfigType.New;
  @Input() canChangeOnly = false;
  @Input() selectedAll = false;
  @Input() separateBySteps = false;
  @Input() showIncidentValues = true;
  @Output() backAction = new EventEmitter<void>();
  @Output() onSelect = new EventEmitter<any>();

  steps: Array<StepBuilderInterface>;
  checkboxSelectAll: boolean;
  fields: Array<FieldModel>;
  formValues = {};
  FieldType = FieldTypeEnum;
  loading: boolean;
  stepsCheckboxes = {};

  constructor(
    private _documentsService: DocumentsService,
    private route: ActivatedRoute,
    private multiStepsService: MultiStepsService,
    private location: Location,
    private multiStepsRESTService: MultiStepsRESTService,
    private dateFormat: DateFormatPipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.loading = true;
    this.getConfigs();
    this.subscribeOnRouteData();
  }

  ngOnChanges() {
    this.mapSteps();
  }

  upload(file): void {
    this._documentsService.downloadBlobFile(file);
  }

  emitBackAction(): void {
    if (this.back.isInsideModal) {
      this.backAction.emit();
    } else {
      this.location.back();
    }
  }

  selectAll(event): void {
    this.selectedValues = event.checked ? this.fields : [];
    if(this.separateBySteps){
      Object.keys(this.stepsCheckboxes).forEach((key: string) => this.stepsCheckboxes[key] = event.checked);
    }
    this.emitSelections();
  }

  selectStep(event: any, fields: FieldModel[]): void {
    if(event.checked){
      this.selectedValues = this.selectedValues.concat(fields);
    }else{
      this.selectedValues = this.selectedValues.filter((field) => !fields.includes(field));
    }
    this.emitSelections();
  }

  emitSelections(): void {
    if (this.selectedValues?.length === this.fields?.length) {
      this.checkboxSelectAll = true;
    } else {
      this.checkboxSelectAll = false;
    }
    const obj = {
      fieldsData: this.selectedValues.map((field) => this.getMappedField(field)),
      incident: this.incident,
    };
    this.onSelect.emit(obj);
  }

  getMappedField(field: FieldModel) {
    switch (field?.type) {
      case FieldTypeEnum.FormArray:
        return {
          field,
          value: this.formValues[field.property]
            ?.map((item) =>
              Object.keys(item)
                .map((element: any) => {
                  if (element !== 'id') {
                    return item[element]?.name || item[element];
                  }
                })
                .join(' ')
            )
            .join(','),
        };
      case FieldTypeEnum.LinkIncident:
        return {
          field,
          value: this.formValues[field.property]?.map((item) => item?.incidentNumber).join(','),
        };
      case FieldTypeEnum.Custom:
        return { field, value: this.incident[field.property] || field.value };
      case FieldTypeEnum.DatePicker:
        return {
          field,
          value: this.dateFormat.transform(this.formValues[field.property], DateFormat.date),
        };
      case FieldTypeEnum.TimePicker:
        return {
          field,
          value: this.dateFormat.transform(this.formValues[field.property], DateFormat.time),
        };
      case FieldTypeEnum.TreePicker:
      case FieldTypeEnum.Dropdown:
      case FieldTypeEnum.Radiobutton:
        return {
          field,
          value: this.incident?.[field?.property?.replace('Id', '')]
            ? this.incident?.[field?.property?.replace('Id', '')]?.name ||
              this.incident[field.property]
            : this.incident[field.property],
        };
      case FieldTypeEnum.Checkbox:
        return {
          field,
          value: field?.options
            ?.filter(
              (item) =>
                this.formValues[field?.property]?.includes(item[field?.['optionKey']]) ||
                this.formValues[field?.property]?.includes(item?.id)
            )
            .map((item) => item?.name || item)
            .join(','),
        };
      case FieldTypeEnum.AutoComplite:
        return {
          field,
          value: this.formValues[field.property]?.name || this.formValues[field.property]?.name,
        };
      default:
        return {
          field,
          value: this.formValues[field.property]?.fullName || this.formValues[field.property],
        };
    }
  }

  private getFields(steps: Array<StepBuilderInterface>) {
    let fields = [];
    steps.forEach((step: StepBuilderInterface) => {
      fields.push(...(step.fields || []));
    });
    if (this.selectedOnly) {
      fields = fields.filter((f: FieldModel) => {
        return (this.selectedValues || []).find((field) => {
          return (
            field?.id === f?.id ||
            field?.property === f?.property ||
            field?.field?.id === f?.id ||
            field?.field?.id === f?.id ||
            field?.field?.property === f?.property
          );
        });
      });
    }
    return fields;
  }

  private getConfigs(): void {
    this.multiStepsRESTService
      .getFormConfig(this.configType, {}, this.incident?.incidentId)
      ?.pipe(
        map((results: any) => this.multiStepsService.getStepsAndFields(results)),
        takeUntil(this.$destroy)
      )
      .subscribe((steps: StepBuilderInterface[]) => {
        this.steps = steps;
        if(!this.separateBySteps){
          this.steps[0].fields.unshift(
          {
            property: 'incidentNumber',
            id: 'custom1',
            key: 'ui.general.referencenumber',
            type: 'custom',
            value: this.incident?.incidentNumber,
          } as any,
          {
            property: 'Rapportör',
            id: 'custom2',
            key: 'Rapportör',
            value: this.incident?.registeredBy?.fullName,
            type: 'custom',
          } as any
        );
        }
        this.initializeStepsCheckboxes();
        this.mapSteps();
      });
  }

  checkSteps(step: StepBuilderInterface): void {
    if(this.separateBySteps){
      const stepSelected = step.fields.every((field: FieldModel) => this.selectedValues.includes(field));
      this.stepsCheckboxes[step.id] = stepSelected;
    }
  }

  private initializeStepsCheckboxes(): void {
    this.steps.forEach((step: StepBuilderInterface) => {
      this.stepsCheckboxes[step.id] = false;
    });
  }

  private subscribeOnRouteData(): void {
    this.route.data.pipe(takeUntil(this.$destroy)).subscribe((data) => {
      if (data.incident) {
        this.incident = data.incident;
      }
    });
  }

  private mapSteps(): void {
    if (!this.steps || !this.steps?.length || !this.incident) {
      return;
    }
    this.fields = this.getFields(this.steps);
    this.fields.forEach((f) => {
      Object.assign(this.formValues, {
        [f.property]: this.multiStepsService.getFormValueByEntityValues(f, this.incident),
      });
    });
    if(this.selectedAll){
      this.checkboxSelectAll = true;
      this.selectedValues = this.fields;
    }
    this.loading = false;
  }
}
