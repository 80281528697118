import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import { map, takeUntil } from 'rxjs/operators';

import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import {
  actionMode,
  MultiTreePickerMapInterface,
} from '@app/shared/components/multi-tree-picker/models/multi-tree-picker.interface';
import { Type } from '@app/audit/modules/questionnaires/models/questionnaries.interface';
import { MultiStepsRESTService } from '@app/step-builder/services/rest-services/multi-steps.services';
import { QuestionnairesRESTService } from '@app/audit/modules/questionnaires/services/rest-services/questionnaires.service';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';
import { OptionsTypeEnum } from '@app/step-builder/models/options-type.enum';
import { flatten } from '@app/shared/utils/flatten-tree';

@Component({
  selector: 'app-multi-tree-picker',
  templateUrl: './multi-tree-picker.component.html',
  styleUrls: ['./multi-tree-picker.component.less'],
})
export class MultiTreePickerComponent extends UnsubscriberComponent implements OnInit, OnChanges {
  @Input() field: any;
  @Input() form: FormGroup;
  @Input() options;
  @Input() recentlyReportedLocations: any[];
  @Input() controlForDisplay: FormControl = new FormControl();
  @Input() parentClass: string = 'tree-picker-parent';
  @Input() type: string;

  @ViewChild('myDialog') dialog: OverlayPanel;
  public selectedValue = [];
  public loading: boolean = false;
  flattenOptions = [];

  constructor(
    private messageService: MessageService,
    private multiStepsRESTService: MultiStepsRESTService,
    private translate: TranslatePipe,
    private questionnaireService: QuestionnairesRESTService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.recentlyReportedLocations?.currentValue) {
      this.recentlyReportedLocations =
        this.recentlyReportedLocations?.map((l) => this.mapLocation(l)) || [];
    }
  }

  private getValueName(id: number): string {
    return this.selectedValueInfo.find((item) => item.data === id)?.label;
  }

  get selectedValueInfo(): MultiTreePickerMapInterface[] {
    return this.flattenOptions
      .filter((item) => item.data == this.selectedValue.find((element) => element == item.data))
      .map((item) => item);
  }

  delete(id: number): void {
    this.getOptions();
    const index = this.selectedValue.findIndex((element) => element == id);
    this.selectedValue.splice(index, 1);
  }

  ngOnInit(): void {
    if (!this.options?.length) {
      (this.field || {}).options = [];
      this.options = (this.field || {}).options;
      this.getOptions();
    } else if (this.type) {
      this.options = this.mappedData(this.options, this.type)?.array || [];
    }
    this.selectedValue = this.form.get(this.field.property).value ?? [];
    this.controlForDisplay.valueChanges.pipe(takeUntil(this.$destroy)).subscribe((value) => {
      if (!!value) {
      } else {
        this.selectedValue = null;
      }
    });
  }

  nodeSelect($event: MouseEvent, id: number) {
    $event.stopImmediatePropagation();
    this.changeValue(id, actionMode.Add);
  }

  changeValue(id: number, mode: string): void {
    switch (mode) {
      case actionMode.Add:
        if (this.selectedValue.length) {
          this.messageService.add({
            severity: 'info',
            summary: this.translate.transform('ui.messages.object.added'),
            detail: this.getValueName(id),
          });
        }
        break;
      case actionMode.Delete:
        this.messageService.add({
          severity: 'error',
          summary: this.translate.transform('ui.messages.object.deleted'),
          detail: this.getValueName(id),
        });
        this.delete(id);
        break;
    }
    if (this.form) {
      this.form.get(this.field.property).setValue(this.selectedValue);
      if (this.form.value[this.field.property.replace('Id', '')]) {
        this.form.get(this.field.property.replace('Id', '')).setValue(this.selectedValueInfo);
      } else {
        this.form.addControl(
          this.field.property.replace('Id', ''),
          new FormControl(this.selectedValueInfo)
        );
      }
    }
  }

  toggleDialog($event: MouseEvent | Event | PointerEvent): void {
    if (!($event as PointerEvent).x && !($event as PointerEvent).y) {
      return;
    }
    if (this.dialog.overlayVisible) {
      this.dialog.hide();
    } else {
      this.dialog.show($event);
    }
  }

  expandNode(e): void {
    e.node.expanded = !Boolean(!!e.node.expanded);
  }

  getOptions(): void {
    if (!this.field) {
      return;
    }
    this.loading = true;
    this.multiStepsRESTService
      .getOptionsByUrl(this.field.optionsUrl, this.field.queryParams)
      .pipe(
        takeUntil(this.$destroy),
        map((response: any) => {
          return this.mappedData(response, this.field.optionsUrl);
        })
      )
      .subscribe((results: any) => {
        this.recentlyReportedLocations =
          results?.response?.recentlyReportedLocations.map((l) => this.mapLocation(l)) || [];
        this.options.length = 0;
        this.options.push(...(results.array || []));
        const extractChildren = (x) => x?.children || [];
        this.flattenOptions = flatten('data', this.options, extractChildren)?.map(
          (x) => delete x.children && x
        );
        this.loading = false;
      });
  }

  private mappedData(response, type) {
    switch (type) {
      default:
        if (type.includes(Type.QuestionSet)) {
          return {
            array: this.questionnaireService
              .mapQuestionnaire(response, 'id', 'questionSetId')
              .map((element) => {
                return this.mapLocation(element);
              }),
          };
        } else if (type.includes(OptionsTypeEnum.Organizations)) {
          return response;
        }
        return {
          array: response?.map((element) => this.mapLocation(element)),
        };
    }
  }

  private mapLocation(location): MultiTreePickerMapInterface {
    const input = {
      data: location?.id,
      label: location?.name,
      children: location?.children ? location?.children?.map((item) => this.mapLocation(item)) : [],
    };
    return input;
  }
}
