import { Pipe, PipeTransform } from '@angular/core';
import { IncidentFullObject } from '@app/incidents/models/incident.interface';
import { FieldModel, FieldTypeEnum, ParamsValueByKeysFromUrl } from '@app/step-builder/models';
import { getSelectedNodeName } from '../utils/find-tree-node.recursion';
import { MultiStepsService } from '@app/step-builder/services/multi-steps.service';
import { MultiStepsRESTService } from '@app/step-builder/services/rest-services/multi-steps.services';

@Pipe({
  name: 'optionName',
})
export class OptionNamePipe implements PipeTransform {
  constructor(
    private multiStepsService: MultiStepsService,
    private multiStepsRESTService: MultiStepsRESTService
  ) {}

  transform(
    value: any,
    field: FieldModel,
    incident: IncidentFullObject = null,
    formValue?
  ): Promise<any> {
    const promise = new Promise((resolve) => {
      if (!field) {
        resolve(value);
      }

      if (value && value.name) {
        resolve(value.name);
      }

      if (incident && field.property && field.property.includes('Id')) {
        const incidentValue = incident?.[field.property.replace('Id', '')];
        if (incidentValue && incidentValue.name) {
          resolve(incidentValue.name);
        }
      }

      if (value && value.map && value[0] && value[0].name) {
        resolve(value.map((item) => item.name).join(', '));
      }

      let option;

      if (
        field.type !== FieldTypeEnum.TreePicker &&
        (field.options?.length || !!field.optionsUrl) &&
        field.type !== FieldTypeEnum.MultiSelect
      ) {
        option = (field.options || []).find(
          (option: { id: any; name: any }) => option.id === value
        );
        if (!!field.optionsUrl && formValue && Object.keys(formValue).length) {
          const params: ParamsValueByKeysFromUrl =
            this.multiStepsService.getParamsValueByKeysFromUrl(field.optionsUrl, formValue);
          if (!Object.keys(params).length || !formValue[field.property]) {
            resolve(value);
            return;
          }
          this.multiStepsRESTService.getOptionsByUrl(field.optionsUrl, params).subscribe((res) => {
            field.options = res;
            option = (field.options || []).find(
              (option: { id: any; name: any }) => option.id === value
            );
            resolve(option ? option.name || option.label : '');
          });
        }
      }
      if (field.type === FieldTypeEnum.TreePicker) {
        if (!!incident?.[field.property.replace('Id', '')]) {
          const treeValueObj = incident?.[field.property.replace('Id', '')];
          resolve(
            treeValueObj
              ? treeValueObj.name
                ? treeValueObj.name
                : treeValueObj.label
              : treeValueObj
          );
        } else {
          option = getSelectedNodeName(field.options, value);
          resolve(option ? option?.name || option?.label : '');
        }
      }
      if (field.type === FieldTypeEnum.MultiTreePicker) {
        resolve(
          incident[field.property.replace('Id', '')]
            .map((element) => (element.name ? element.name : element.label))
            .join()
        );
      }
      if (field.type === FieldTypeEnum.MultiSelect) {
        const getOptions = (options) => {
          return field.optionKey
            ? field?.options
                .filter((item) => options.includes(item?.[field.optionKey]))
                .map((item) => item?.name)
                .join()
            : options.join();
        };

        if (field?.options?.length) {
          resolve(getOptions((incident || formValue)?.[field.property]));
        }
        if (field?.optionsUrl) {
          this.multiStepsRESTService.getOptionsByUrl(field.optionsUrl).subscribe((res) => {
            field.options = res;

            resolve(getOptions((incident || formValue)?.[field.property]));
          });
        }
      }
      if (!field.optionsUrl) {
        resolve(option ? option.name || option.label : value);
      }
    });
    return promise;
  }
}
