import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { RoutingParameterTypes } from '../models/routing-parameter-types.enum';

@Injectable({
  providedIn: 'root',
})
export class RedirectTo404Guard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const type = route?.data?.routingType;
    const paramName = route?.data?.paramName;
    const parameter = route.params?.[paramName];
    const enumParam = route?.data?.enum;
    switch (type) {
      case RoutingParameterTypes.Enum:
        if (Object.values(enumParam).includes(parameter)) {
          return true;
        }
        break;
      case RoutingParameterTypes.Integer:
        if (typeof Number(parameter) === 'number' && !Number.isNaN(Number(parameter))) {
          return true;
        }
        break;
      case RoutingParameterTypes.Combined:
        if (
          (Number.isNaN(Number(parameter)) && Object.values(enumParam).includes(parameter)) ||
          (typeof Number(parameter) === 'number' && !Number.isNaN(Number(parameter)))
        ) {
          return true;
        }
        break;
    }
    this.router.navigate(['/404']);
  }
}
