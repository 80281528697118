import { Injectable } from '@angular/core';
import { MapBufferToBlobServiceInterface } from '@app/shared/models/map-buffer-to-blob-service.interface';

@Injectable({
  providedIn: 'root',
})
export class MapBufferToBlobService implements MapBufferToBlobServiceInterface {
  mapBufferToBlob(arrayBuffer): Blob {
    const hex = [...new Uint8Array(arrayBuffer)]
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')
      .slice(0, 8)
      .toLocaleUpperCase();

    const mimetype = this.getMimetype(hex);
    return new Blob([arrayBuffer], { type: mimetype });
  }
  private getMimetype(signature: string): string {
    switch (signature) {
      case '89504E47':
        return 'image/png';
      case '47494638':
        return 'image/gif';
      case '25504446':
        return 'application/pdf';
      case 'FFD8FFDB':
      case 'FFD8FFE0':
        return 'image/jpeg';
      case '504B0304':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      case 'EFBBBF22':
        return 'text/csv';
      case '74657374':
        return 'text/plain';
      case '7B0D0A20': 
        return 'application/json';
      default:
        return 'Unknown filetype';
    }
  }
}
