import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-attach-parties-flow',
  templateUrl: './attach-parties-flow.component.html',
  styleUrls: ['./attach-parties-flow.component.less'],
})
export class AttachPartiesFlowComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter();
  @Output() attached = new EventEmitter();

  activeStep = Steps.Confirmation;
  Steps = Steps;
  constructor() {}

  ngOnInit(): void {
    this.resetActiveStep();
  }

  hideDialog($event) {
    this.resetActiveStep();
    this.closed.emit($event);
  }

  attachParties($event) {
    this.resetActiveStep();
    this.attached.emit($event);
  }

  resetActiveStep() {
    setTimeout(() => (this.activeStep = Steps.Confirmation), 500);
  }

  ngOnDestroy() {
    this.resetActiveStep();
  }
}

export enum Steps {
  Confirmation,
  PartiesForm,
}
