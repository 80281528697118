import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { UnsubscriberComponent } from '../unsubscriber/unsubscriber.component';
import { takeUntil } from 'rxjs/operators';
import { passwordValidator } from '../validators/password-validators';

@Component({
  selector: 'app-double-dynamic-validator',
  template: '',
})
export class DoubleDynamicValidatorComponent extends UnsubscriberComponent implements OnInit {
  dynamicRequiredFields: any = {};
  passwordKey = 'password';
  minPasswordLength: number;
  constructor(public _cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {}

  setDynamicValidators(form: FormGroup, fields: string[], attributes?: any[]): void {
    fields.forEach((field) => {
      // set default required state for form and field labels
      Object.assign(this.dynamicRequiredFields, { [field]: true });
      let defaultValidators = [Validators.required];

      if (field === this.passwordKey) {
        defaultValidators = [Validators.minLength(this.minPasswordLength), passwordValidator(form)];
      }
      form.get(field).setValidators(defaultValidators);
      this.updateValidity(field, form);
      // subscribe for changes

      form
        .get(field)
        .valueChanges.pipe(takeUntil(this.$destroy))
        .subscribe((value) => {
          const otherField = fields.find((el) => el !== field);
          this.updateValidity(field, form);
          this.updateValidity(otherField, form);

          const isValid = form.get(field).valid;
          const isAnyFieldValid = isValid && form.controls[otherField].valid;

          if (isAnyFieldValid || !value) {
            this.checkPasswordControl(field, otherField, form);
          }
          if (isAnyFieldValid) {
            return;
          }

          const validators = !isValid ? [Validators.required] : null;
          this.setValidators(form, otherField, field, validators);
          this.updateValidity(otherField, form);

          const isRequiredOtherField = !isValid;
          this.dynamicRequiredFields[otherField] = isRequiredOtherField;

          if (!!attributes) {
            const found = attributes.find((el) => el.formControlName === otherField);
            found.required = isRequiredOtherField;
          }
        });
    });
  }

  updateValidity(fieldName: string, form: FormGroup): void {
    form.controls[fieldName].updateValueAndValidity({ emitEvent: false, onlySelf: true });
    this._cdr.detectChanges();
  }

  setValidators(
    form: FormGroup,
    otherField: string,
    field: string,
    validators: ValidatorFn[]
  ): void {
    const clearValidators = (f: string) => {
      form.get(f).setValidators(null);
      this.updateValidity(f, form);
    };

    if (otherField !== this.passwordKey) {
      form.get(otherField).setValidators(validators);
      this.updateValidity(otherField, form);
    }

    const isFieldPasswordAndEmpty =
      field === this.passwordKey && !(form.get(field).value || '').toString().length;

    if (isFieldPasswordAndEmpty && form.get(otherField).value) {
      clearValidators(field);
    }
  }

  setPasswordValidators(form: FormGroup, additionalValidators: ValidatorFn[] = []): void {
    form.controls[this.passwordKey].setValidators([
      Validators.minLength(this.minPasswordLength),
      passwordValidator(form),
      ...additionalValidators,
    ]);
    this.updateValidity(this.passwordKey, form);
  }

  private checkPasswordControl(fieldName: string, otherFieldName: string, form: FormGroup): void {
    if (![fieldName, otherFieldName].includes(this.passwordKey)) {
      return;
    }

    this.setPasswordValidators(form);
  }
}
