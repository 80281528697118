import { WorkSpaceUser } from '@app/shared/models/user.interface';

export interface IncidentsRootObject {
  incidents?: IncidentFullObject[];
  attributes?: IncidentAttribute[];
}

export class IncidentMinObject {
  incidentId?: number;
  incidentNumber?: number;
  incidentDate?: Date;
  locationId?: number;
  locked?: boolean;
  privilegeToDelete?: boolean;
  privilegeToChange?: boolean;
  privilegeToAmend?: boolean;
  unread?: boolean;
}

export class IncidentFullObject extends IncidentMinObject {
  id?: number;
  referenceNumber?: number;
  externalId?: number;
  incidentTime?: Date;
  createdDate?: Date;
  modifiedDate?: Date;
  incidentType?: string;
  incidentReporter?: WorkSpaceUser;
  incidentOwner?: WorkSpaceUser;
  genders?: Gender[];
  groups?: Group[];
  description?: string;
  linked?: boolean;
  cause?: string;
  measure?: string;
  involvedParties?: InvolvedParty[];
  linkedIncidents?: IncidentFullObject[];
  privilegeToChange?: boolean;
  privilegeToAmend?: boolean;
  privilegeToDelete?: boolean;
  incidentId?: number;
  location?: any;
  incidentAmendments?: any;
  registeredBy?: any;
  formCurrentStep?: number;
  severityId?: number;
  areaId?: number;
  categoryId?: number;
  detailId?: number;
  subItemId?: number;
  stateInfo?: string;
  stateMessage?: string;
  statusId?: number;
  tags?: Tag[];
  administeredById?: number;
  administeredBy?: any;
  area?: any;
  subItem?: any;
  category?: any;
  detail?: any;
  ownedBy?: any;
  severity?: any;
  status?: Status;
  documents?: Array<any>;
}

interface Gender {
  name: string;
}

interface Tag {
  id?: string;
  name?: string;
}

interface Group {
  name: string;
}

export interface InvolvedParty {
  gender: any;
  group?: any;
  socialsecuritynumber?: string;
  person?: any;
}

export interface AttributeGroup {
  label: string;
  activeIndex: number;
  alert?: boolean;
  enabled?: boolean;
  items?: string;
  languageKey?: number;
  locationKey?: number;
}

export class IncidentAttribute {
  canChange: boolean;
  helpText: string;
  id: number;
  key: string;
  longName: string;
  name: string;
  placeholder: string;
  privilegeValue: number;
  required: boolean;
  uuid: string;
  visible: boolean;
  workspaceAttributeId?: number;
  order?: number;
}

export interface Status {
  color: string;
  incidentStateId: number;
  incidentState: IncidentState;
  id: number;
  key: string;
  name: string;
  order: number;
}

export interface IncidentState {
  id: number;
  key: string;
  name: string;
  order: number;
  field: string;
  value: States;
}

export interface DuplicateData {
  id: number,
  property: string,
}

export enum States {
  Open = 'Open',
  Closed = 'Closed',
  Rejected = 'Rejected',
}

export enum ReportButtons{
  Edit = 'edit',
  Copy = 'copy'
}
