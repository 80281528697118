import { Injectable } from '@angular/core';
import { LocationKey } from '@app/shared/models/location-key.enum';

@Injectable({
  providedIn: 'root',
})
export class ServerRouteAdapter {
  mapRoutingKeyToActualRouting(routingKey: LocationKey): string {
    switch (routingKey) {
      // Incident
      case LocationKey.Incident:
        return 'incidents';
      case LocationKey.IncidentCreate:
        return 'incidents/create';
      case LocationKey.IncidentDraft:
        return 'incidents/drafts/all';
      case LocationKey.IncidentSent:
      case LocationKey.IncidentOutbox:
        return 'incidents/outbox/all';
      case LocationKey.IncidentTrash:
        return 'incidents/trash/all';
      case LocationKey.IncidentAnalysis:
        return 'incidents/analysis/filter/all';
      case LocationKey.IncidentInbox:
        return 'incidents/inbox/all';

      // Audit
      case LocationKey.AuditCreate:
        return 'audit/create';
      case LocationKey.AuditAuditlist:
      case LocationKey.AuditAuditlistDetails:
        return 'audit/auditlist';
      case LocationKey.AuditQuestionset:
      case LocationKey.AuditQuestionSetDetails:
        return 'audit/questionset';

      // Interviews
      case LocationKey.AuditMyinterviews:
        return 'audit/myinterviews';
      case LocationKey.AuditMyassessments:
        return 'audit/myassessments';
      case LocationKey.AuditMysurveys:
        return 'audit/mysurveys';
      case LocationKey.AuditCreateInterview:
        return 'audit/create_interview';

      // Organization/object
       case LocationKey.Organization:
        return 'organizations';
      case LocationKey.OrganizationInformation:
      case LocationKey.OrganizationRisks:
        return 'organizations/information';
      case LocationKey.OrganizationAudits:
        return 'organizations/all/audits';
      case LocationKey.OrganizationUsers:
        return 'organizations/all/users';
      case LocationKey.OrganizationIncidents:
        return 'organizations/all/incidents';

      // Tasks
       case LocationKey.Task:
        return 'tasks';
      case LocationKey.TaskCreate:
        return 'tasks/create';
      case LocationKey.TaskOutbox:
        return 'tasks/outbox';
      case LocationKey.TaskInbox:
      case LocationKey.TaskTrash:
        return 'tasks/inbox';

      // Admin
      case LocationKey.AdminPrivileges:
        return 'admin/privileges/all';
      case LocationKey.AdminPrivilegesUsers:
        return 'admin/privileges/all/users';
      case LocationKey.AdminPrivilegesRoles:
        return 'admin/privileges/all/roles';
      case LocationKey.AdminPrivilegesSessions:
        return 'admin/privileges/all/sessions';
      case LocationKey.AdminTrashcan:
        return 'admin/privileges/trash';
      case LocationKey.AdminTrashcanUsers:
        return 'admin/privileges/trash/users';
      case LocationKey.AdminTrashcanRoles:
        return 'admin/privileges/trash/roles';
      case LocationKey.AdminTaskStatuses:
        return 'admin/task/statuses';
      case LocationKey.AdminIncident:
      case LocationKey.AdminIncidentWorkLists:
        return 'admin/incident/worklists/all';
      case LocationKey.AdminIncidentStatuses:
        return 'admin/incident/statuses';

           case LocationKey.Logout:
        return '/auth/sign-in';
        case LocationKey.UserSettings:
        return 'helper/usersettings';

      // Default
      default:
        return routingKey.replaceAll('_', '/');
    }
  }
}
