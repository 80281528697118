export enum TranslationKey {
  Incident = 'ui.incident',
  Admin = 'ui.admin',
  System = 'ui.system',
  Organization = 'ui.object',
  Task = 'ui.task',
  Audit = 'ui.audit',
  Navigation = 'ui.main.navigation',
  Login = 'ui.login',
  Settings = 'ui.settings',
  Questionnaire = 'ui.questionnaire',
  All = 'All',
}
