import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';

import { AuthenticationService } from '@app/auth/services/authentication.service';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';
import { Audit, Audits, MessageModel, MessageTemplateModel } from '@app/audit/models/audit.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuditsRESTService {
  constructor(
    private http: HttpClient,
    private authenticationRESTService: AuthenticationRESTService
  ) {}

  get(): Observable<Audits> {
    const url = `${this.authenticationRESTService.baseUrl()}/Audits/inbox`;
    return this.http.get<Audits>(url).pipe(
      map((audit) =>
        audit.map((element) => {
          element.organizationName = element.organization.name;
          return element;
        })
      )
    );
  }

  getById(id: number): Observable<Audit> {
    const url = `${this.authenticationRESTService.baseUrl()}/Audits/${id}`;
    return this.http.get<Audit>(url);
  }

  delete(ids: number[], isSoftDelete: boolean = true): Observable<Audits> {
    const requests: Array<any> = ids.map((id: number) => {
      const url = `${this.authenticationRESTService.baseUrl()}/Audits/${id}?softDelete=${isSoftDelete}`;
      return this.http.delete(url);
    });
    return combineLatest<Audits>(requests);
  }

  update(id: number, body: any): Observable<HttpEvent<Audit>> {
    const url = `${this.authenticationRESTService.baseUrl()}/Audits/${id}`;
    return this.http.put<Audit>(url, body, {reportProgress: true, observe: 'events'});
  }

  create(body: any): Observable<HttpEvent<Audit>> {
    const url = `${this.authenticationRESTService.baseUrl()}/Audits/`;
    return this.http.post<Audit>(url, body, {reportProgress: true, observe: 'events'});
  }

  getStateChanges(id: number): Observable<string[]> {
    const url = `${this.authenticationRESTService.baseUrl()}/Audits/${id}/state/validStateChanges`;
    return this.http.get<string[]>(url);
  }

  changeState(id: number, state: string): Observable<any> {
    const url = `${this.authenticationRESTService.baseUrl()}/Audits/${id}/state/${state}`;
    return this.http.put<any>(url, {
      message: 'string',
    });
  }
  getByOrganizationId(id: number): Observable<Audits> {
    const url = `${this.authenticationRESTService.baseUrl()}/Organizations/${id}/audits`;
    return this.http.get<Audits>(url);
  }

  getMessageTemplates(id?: number): Observable<MessageTemplateModel[]> {         
    const params = { 
      module: 'AUDIT',
    };
    if (id) {
      Object.assign(params, { id })
    }
    const url = `${this.authenticationRESTService.baseUrl()}/Users/Messages/Templates`;
    return this.http.get<MessageTemplateModel[]>(url, {
      params
    });
  }

  sendMessage(message: MessageModel): Observable<void> {
    const url = `${this.authenticationRESTService.baseUrl()}/Users/Messages/Send`;
    return this.http.post<void>(url, { ...message });
  }
}
