import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, take } from 'rxjs';
import { ThemeModel, ThemeModelServerResponse } from '../models/theme.interface';
import { ClientStorageService } from './client-storage.service';
import { ThemesService } from '../../helper/modules/settings/services/rest-service/themes.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LessService {

  private themeObject: ThemeModelServerResponse;

  constructor(http: HttpClient,
    private storageService: ClientStorageService) {
    const themeFromStorage: ThemeModelServerResponse = storageService.getValue(storageService.KEYS.THEME);
    if (!themeFromStorage) {
      http.get('../../../assets/themes/isap-theme.json', { responseType: 'text' })
        .pipe(take(1))
        .subscribe((response) => {
          const vars: ThemeModelServerResponse = JSON.parse(response);
          this.themeObject = vars;
          this.setTheme(this.themeObject);
        });
    } else {
      this.setTheme(themeFromStorage);
    }
  }

  setTheme(theme: ThemeModelServerResponse = this.themeObject): void {
    let styles: string = '';
    this.themeObject = theme;
    Object.keys(theme).forEach((key: string, index: number) => {
      if (index === 0) {
        styles += `:root {`;
      }

      styles += `--${key}: ${theme[key]};`;

      if (index === Object.keys(theme).length - 1) {
        styles += `}`;
      }
    });
    const sheet = document.createElement('style');
    sheet.innerText = styles;
    sheet.title = 'isap-theme';
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(sheet);
    this.storageService.setValue(this.storageService.KEYS.THEME, theme);
  }
}
