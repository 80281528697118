import { Component, OnInit } from '@angular/core';
import { IsapToasterService } from './service/isap-toaster.service';
import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-isap-toaster',
  templateUrl: './isap-toaster.component.html',
  styleUrls: ['./isap-toaster.component.less'],
})
export class IsapToasterComponent extends UnsubscriberComponent implements OnInit {
  visible: boolean;

  constructor(public isapToaster: IsapToasterService) {
    super();
  }

  ngOnInit(): void {
    this.isapToaster.$panelMessage.pipe(takeUntil(this.$destroy)).subscribe((a) => {
      this.visible = !!a;
    });
  }
}
