export enum OptionsTypeEnum {
  Gender = 'Gender',
  PersonGroup = 'PersonGroup',
  PersonCategory = 'PersonCategory',
  IncidentType = 'IncidentType',
  IncidentStatus = 'IncidentStatus',
  IncidentSeverity = 'IncidentSeverity',
  IncidentPriority = 'IncidentPriority',
  IncidentArea = 'IncidentArea',
  IncidentCategory = 'IncidentCategory',
  IncidentDetail = 'IncidentDetail',
  IncidentDetailItem = 'IncidentDetailItem',
  IncidentSubItem = 'IncidentSubItem',
  Tag = 'Tag',
  Users = 'Users',
  Organizations = 'Organizations',
  OrganizationType = 'OrganizationType',
}
