import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { User, UserProfile, Workspace, WorkSpaceUser } from '@app/shared/models/user.interface';
import { ClientStorageService } from '@app/shared/services/client-storage.service';
import { StateValue } from './state-value';
import { AuthenticationLogicServiceInterface } from '@app/auth/models/authentication-service.interface';
import { ServerRouteAdapter } from '@app/auth/services/server-route-adapter.service';
import { LocationKey } from '@app/shared/models/location-key.enum';
import { getParamsObjectFromUrl } from '@app/shared/utils/get-params-object-from-url';
import { LOG_OUT_ROUTES } from '../models/routingkeys.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements AuthenticationLogicServiceInterface {
  constructor(
    private router: Router,
    private storage: ClientStorageService,
    private serverRouteAdapter: ServerRouteAdapter,
  ) {
    // user in local storage
    const userJson = this.storage.getValue(this.storage.KEYS.USER);

    this.CurrentUser = new StateValue<User>(userJson);
    this.isAuthenticated.set(userJson != null);
  }

  // PROPERTIES
  //private env = `${environment.apiUrl}`;
  public CurrentUser: StateValue<User>;
  public profile: StateValue<UserProfile> = new StateValue<UserProfile>(null);
  public isAuthenticated: StateValue<boolean> = new StateValue<boolean>(false);

  clearStorageAndLogout(navigateTo?: LOG_OUT_ROUTES): void {
    // clear out token from memory and local storage
    this.CurrentUser.set(null);

    // flag as not autenticated user
    this.isAuthenticated.set(false);
    this.clearCookies();
    this.storage.clear();
    this.clearRefreshToken();
    if (navigateTo) {
      this.navigateAfterLogOut(navigateTo);
    }
  }

  clearCookies(): void {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

  }

  // REMOVE LOCAL STORAGE KEYS ON NEW USER CALL
  resetStorageContent(): void {
    this.storage.removeItem(this.storage.KEYS.INCIDENT_FILTER);
  }

  // GET THE CACHED USER PROFILE
  getUserProfile(): UserProfile {
    return this.storage.getValue(this.storage.KEYS.USER) || {};
  }

  clearRefreshToken(): void {
    this.storage.removeItem(this.storage.KEYS.REFRESHTOKEN);
  }

  // GET THE CACHED USER PROFILE
  getToken(): string {
    return this.storage.getValue(this.storage.KEYS.TOKEN) || '';
  }

  // SAVE TOKEN
  saveToken(token: string): void {
    this.storage.setValue(this.storage.KEYS.TOKEN, token);
  }

  // GET THE WORKSPACE
  getWorkspaceId(): string {
    return this.storage.getValue(this.storage.KEYS.SELECTED_WORKSPACE) || '';
  }

  getUserPermissions() {
    return this.storage.getValue(this.storage.KEYS.USER_PERMISSIONS) || {};
  }

  saveAllowedDowntime(inactivityThreashold: number, heartbeatInterval: number): void {
    this.storage.setValue(this.storage.KEYS.INTERVALS, {
      inactivityThreashold: inactivityThreashold * 60000,
      heartbeatInterval: heartbeatInterval * 60000,
    });
  }

  getAllowedDowntime() {
    return this.storage.getValue(this.storage.KEYS.INTERVALS);
  }

  // GET THE WORKSPACE
  getLanguageId(): string {
    const language = this.storage.getValue(this.storage.KEYS.SELECTED_LANGUAGE);
    return language ? language.languageId : '';
  }

  getLanguageCode(): string {
    const language = this.storage.getValue(this.storage.KEYS.SELECTED_LANGUAGE);
    return language ? language.languageCode : '';
  }

  redirectInsideApp(
    defaultRedirectUrl: string = `workspaces/${this.getWorkspaceId()}`,
    workspace: Workspace = this.getUserProfile().activeWorkspace,
    loggedOutReturnUrl?: string
  ): void {
    if(loggedOutReturnUrl){
      const redirectData = this.getRedirectData(loggedOutReturnUrl);
      if (redirectData.url.includes('(detailInfo:')) {
        this.router.navigateByUrl(redirectData.url)
          .catch((err) => this.catchPermissionsError(err.error));
      } else {
        this.router.navigate([redirectData.url], { queryParams: { ...redirectData.params } })
          .catch((err) => this.catchPermissionsError(err.error));
      }
      return;
    }
    const activeWorkspaceRedirectKey =
      !!workspace && workspace.navigationStart ? workspace.navigationStart : null;
    if (activeWorkspaceRedirectKey) {
      this.router.navigate([
        `${defaultRedirectUrl}/${this.serverRouteAdapter.mapRoutingKeyToActualRouting(
          activeWorkspaceRedirectKey as LocationKey
        )}`,
      ]);
      return;
    }
    if(defaultRedirectUrl){
      this.router.navigate([defaultRedirectUrl]);
      return;
    }
  }

  private getRedirectData(loggedOutReturnUrl: string): {url: string, params?: any} {
    const hasParams = !!loggedOutReturnUrl.split('?')[1];
    if(hasParams){
      const params = getParamsObjectFromUrl(loggedOutReturnUrl);
      const url = `${loggedOutReturnUrl.split('?')[0]}`;
      return {url, params};
    }else{
      return {url: loggedOutReturnUrl};
    }
  }

  private catchPermissionsError(error): void {
    if(error.errorTypeCode === 10){
      this.storage.setValue(this.storage.KEYS.SELECTED_WORKSPACE, this.getUserProfile().activeWorkspace.workspaceId);
      this.redirectInsideApp();
    }
  }

  saveRefreshToken(token: string): void {
    this.clearRefreshToken();
    this.storage.setValue(this.storage.KEYS.REFRESHTOKEN, token);
  }

  getRefreshToken(): string {
    return this.storage.getValue(this.storage.KEYS.REFRESHTOKEN) || '';
  }

  setUserData(user: WorkSpaceUser, loginBody?: WorkSpaceUser): void {
    delete loginBody?.password;

    if (user.token && user.token !== '') {
      // store token in the local storage
      this.storage.setValue(this.storage.KEYS.TOKEN, user.token);
      // flag as autenticated user
      this.isAuthenticated?.set(true);
      // cache user in service
      this.CurrentUser?.set(user);
      // set refresh token
      this.saveRefreshToken(user.refreshToken);
      // saving times for intervals
      this.saveAllowedDowntime(user.inactivityThreashold, user.heartbeatInterval);
    }

    if (loginBody) {
      this.storage.setValue(this.storage.KEYS.USER_PERMISSIONS, {
        ...loginBody,
        fullName: user?.fullName,
      });
    }
    else {
      this.storage.setValue(this.storage.KEYS.USER_PERMISSIONS, {
        fullName: user?.fullName,
        username: user?.userName,
      });
    }

    
  }

  private navigateAfterLogOut(route: LOG_OUT_ROUTES): void {
    this.router.navigate([route]);
  }
}
