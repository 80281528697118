import * as _ from 'lodash';

export const flatten = (key: string, children: any[], getChildren?: any, level?: number, parent?: any) => {
  children = children?.map(item => creatingAnEmptyChild(item));
  return Array?.prototype?.concat?.apply(
    children?.map(x => ({ ...x, level: level || 1, parent: _.cloneDeep(parent) || null })),
    children?.map(x => flatten(key, getChildren(x) || [], getChildren, (level || 1) + 1, x?.[key]))
  );
};

export const creatingAnEmptyChild = (object: any) => {
  object.children = (object?.children) ? object.children : [];
  return object;
};
