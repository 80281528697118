import { WorkspaceSetting } from '../../models/workspace-setting.interface';
import { WorkspacesService } from '../../services/rest-services/workspaces.service';
import { MessageService } from 'primeng/api';
import {
  Component,
  Input,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
  ElementRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DocumentsService } from '@app/documents/services/documents.service';
import { FieldModel } from '@app/step-builder/models';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { UnsubscriberComponent } from '@app/shared/helpers/unsubscriber/unsubscriber.component';
import { DocumentsRESTService } from '@app/documents/services/rest-services/documents.service';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.less'],
})
export class UploadFileComponent extends UnsubscriberComponent implements OnInit {
  @ViewChild('attachPartiesFlowDialog') attachPartiesFlowDialog: OverlayPanel;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('uploadFile') uploadFile: ElementRef;
  @Output() onSave = new EventEmitter();
  @Input() field: FieldModel;
  @Input() header: string;
  @Input() control: FormControl;
  @Input() manualSave: boolean = false;
  @Input() fileOpenMode: string = 'download';
  files: any[];
  fileTypesString: string;
  fileTypes: Array<string>;
  fileSizeLimit: number;

  constructor(
    private documentsService: DocumentsService,
    private documentsRESTService: DocumentsRESTService,
    private messageService: MessageService,
    private workspacesService: WorkspacesService,
    private translate: TranslatePipe) {
    super();
  }

  ngOnInit() {
    this.subscribeOnWorkspaceSetting();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && this.control) {
      this.files = this.control.value || [];
    }
  }

  handleFileInput($event: any): void {
    const files: FileList = $event.target.files;

    if (!this.files) {
      this.files = [];
    }

    const filterBySizeFiles = Array.from(files).filter(
      (file: File) => !this.fileSizeLimit || file.size < this.fileSizeLimit);

    if (filterBySizeFiles.length < Array.from(files).length) {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.transform('ui.messages.document.filesize.error'),
      });
    }

    const filterByTypeFiles = filterBySizeFiles.filter((file: File) =>
      this.fileTypes.some((fileType: string) =>
        file.name.toLowerCase().includes(fileType.toLowerCase())
      )
    );

    if (filterByTypeFiles.length < filterBySizeFiles.length) {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.transform('ui.messages.document.filetype.error'),
      });
    }

    if (!filterByTypeFiles || !filterByTypeFiles.length) {
      return;
    }
    this.files.push(...filterByTypeFiles);

    this.toggleDialog({ target: document.getElementById('upload-file') } as any);
  }

  deleteFile(index: number): void {
    const file = this.control?.value[index] || this.files[index];
    if (!!file.documentId) {
      this.documentsRESTService
        .delete(file.documentId)
        .pipe(takeUntil(this.$destroy))
        .subscribe(() => {
          this.deleteLocalFile(index);
          this.emitSavedFiles(this.files);
        });
    } else {
      this.deleteLocalFile(index);
    }
    this.fileInput.nativeElement.value = [];
  }

  private subscribeOnWorkspaceSetting() {
    this.workspacesService.setting$.subscribe((setting: WorkspaceSetting) => {
      if (!setting) {
        return;
      }
      this.fileTypes = setting.fileUploadExtensions || [];
      this.fileTypesString = (setting.fileUploadExtensions || [])
        .map((fileExtention: string) => `.${fileExtention}`)
        .toString();
      this.fileSizeLimit = setting.fileUploadMaxSize || 2000000;
    });
  }

  deleteLocalFile(index: number): void {
    this.files.splice(index, 1);
  }

  openFile(index: number): void {
    const file = this.control?.value[index] || this.files[index];
    this.documentsService.openDocument(file);
  }

  downloadFile(index: number): void {
    const file = this.control?.value[index] || this.files[index];
    this.documentsService.downloadBlobFile(file);
  }

  public attachParties(persons: Array<{ name: string }>): void {
    this.toggleDialog();
    this.files[this.files.length - 1].containsPersonalInformation = true;

    if (!persons || !persons.length) {
      this.checkManualSave();
      return;
    }

    this.files[this.files.length - 1].involvedParties = persons;
    this.checkManualSave();

    this.messageService.add({
      severity: 'info',
      summary: `${persons[0].name} och andra är bifogade!`,
    });
  }

  onClose($event?): void {
    this.toggleDialog($event);
  }

  toggleDialog($event?: MouseEvent | Event): void {
    if (this.attachPartiesFlowDialog.overlayVisible) {
      this.attachPartiesFlowDialog.hide();
    } else {
      this.attachPartiesFlowDialog.show($event);
    }
  }

  getSavedDocuments(): Observable<any[]> {
    return (this.save() || of([])).pipe(
      map((data) => {
        if (data && data.length) {
          return data.map((doc) => {
            const file = this.files.find((f) => f.name === doc.fileName) || {};
            doc.involvedParties = file.involvedParties;
            return doc;
          });
        }
      })
    );
  }

  checkManualSave(): void {
    if (this.manualSave) {
      return;
    }
    if (!!this.control) {
      (this.save() || of([])).pipe(takeUntil(this.$destroy)).subscribe((data) => {
        if (data && data.length) {
          const dataIndex = data.findIndex((item) =>
            this.files.map((el) => el?.name).includes(item?.fileName)
          );
          const fileIndex = this.files.findIndex((item) =>
            data.map((el) => el?.fileName).includes(item?.name)
          );
          if (dataIndex > -1 && fileIndex > -1)
            data[dataIndex].containsPersonalInformation =
              this.files[fileIndex]?.containsPersonalInformation;
          this.control.patchValue([
            ...(this.files.filter((file: any) => file.documentId) || []),
            ...(data || []),
          ]);
        }
      });
    } else {
      this.getSavedDocuments()
        .pipe(takeUntil(this.$destroy))
        .subscribe((res: any) => {
          res.forEach((r) => {
            const found = this.files.find((el) => !el.documentId && el.name === r.fileName);
            const i = this.files.indexOf(found);
            this.files.splice(i, 1, r);
          });

          this.emitSavedFiles(this.files);
        });
    }
  }

  private emitSavedFiles(files): void {
    if (this.control) {
      this.control.patchValue(files);
    }
    this.onSave.emit(files);
  }

  private save(): Observable<any> {
    if (!this.files || !this.files.length) {
      return;
    }
    this.messageService.add({
      severity: 'info',
      summary: this.translate.transform('ui.messages.document.upload.inprogress'),
    });
    const newFiles = this.getNewFiles();

    if (!newFiles || !newFiles.length) {
      return;
    }

    return this.documentsRESTService.upload(newFiles).pipe(
      tap({
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.transform('ui.messages.document.upload.error'),
          });
        },
        complete: () => {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.transform('ui.messages.document.upload.done'),
          });
        },
      })
    );
  }

  getNewFiles() {
    return (this.files || []).filter((file: any) => !file.documentId);
  }
}
