import { Injectable } from '@angular/core';
import { SortEvent } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class CustomSortService{
  customSort(event: SortEvent, fields: any[]) {
    event.data.sort((data1, data2) => {
      const value1 =
        data1[event.field] && fields.some((field: string) => !!data1[event.field][field])
          ? data1[event.field][fields.find((field: string) => !!data1[event.field][field])]
          : data1[event.field];
      const value2 =
        data2[event.field] && fields.some((field: string) => !!data2[event.field][field])
          ? data2[event.field][fields.find((field: string) => !!data2[event.field][field])]
          : data2[event.field];
      let result = null;
      result = value1 == null
      && value2 != null ? -1 : result = value1 != null
      && value2 == null ? 1 : result = value1 == null
      && value2 == null ? 0 : result = typeof value1 === 'string'
      && typeof value2 === 'string' ? value1.localeCompare(value2) : value1 < value2 ? -1 : value1 > value2 ? 1 : 0;


      return event.order * result;
    });
  }
}
