import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {
  Questionnaire,
  QuestionnairePrerequisite,
  Questionnaires,
} from '@app/audit/modules/questionnaires/models/questionnaries.interface';
import { AuthenticationRESTService } from '@app/auth/services/rest-service/authentication.service';
import { Child } from '@app/audit/models/audit.interface';

@Injectable({
  providedIn: 'root',
})
export class QuestionnairesRESTService {
  constructor(
    private http: HttpClient,
    private authenticationRESTService: AuthenticationRESTService
  ) { }

  public editDataDetails: any = [];
  private questionnairesRefreshed$: Subject<void | number> = new Subject<void | number>();
  private questionSource = new BehaviorSubject(this.editDataDetails);

  questionnairesRefreshed(): Observable<void | number> {
    return this.questionnairesRefreshed$ as Observable<void | number>;
  }

  getFormValuesForQuestion(questionnaires): void {
    this.questionSource.next(questionnaires);
  }

  get(includeDrafts: boolean = true, flat: boolean = true): Observable<Questionnaire[]> {
    const url = `${this.authenticationRESTService.baseUrl()}/QuestionSets`;
    return this.http.get<Questionnaires>(url, { params: { includeDrafts, flat } });
  }

  refreshQuestionnaires(questioonSetId?: number): void {
    this.questionnairesRefreshed$.next(questioonSetId);
  }

  getById(id: number, params?): Observable<Questionnaire> {
    const url = `${this.authenticationRESTService.baseUrl()}/QuestionSets/${id}`;
    return this.http.get<Questionnaire>(url, { params });
  }

  delete(id: number): Observable<Questionnaire> {
    const url = `${this.authenticationRESTService.baseUrl()}/QuestionSets/${id}`;
    return this.http.delete<Questionnaire>(url);
  }

  update(id: number, body: Questionnaire): Observable<Questionnaire> {
    const url = `${this.authenticationRESTService.baseUrl()}/QuestionSets/${id}`;
    return this.http.put<Questionnaire>(url, body);
  }

  create(body: Questionnaire): Observable<Questionnaire> {
    const url = `${this.authenticationRESTService.baseUrl()}/QuestionSets`;
    return this.http.post<Questionnaire>(url, body);
  }

  exportFile(id: number, format: 'json' = 'json'): Observable<Blob> {
    const url = `${this.authenticationRESTService.baseUrl()}/questionsets/${id}/export-file`;
    return this.http.get<Blob>(url, { params: { format }, responseType: 'blob' as 'json' });
  }

  exportObject(id: number, format: 'json' = 'json'): Observable<Questionnaire> {
    const url = `${this.authenticationRESTService.baseUrl()}/questionsets/${id}/export`;
    return this.http.get<Questionnaire>(url);
  }

  import(file: File, importAsNew: boolean = true): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const url = `${this.authenticationRESTService.baseUrl()}/questionsets/import-file`;
    return this.http.post<void>(url, formData, { params: { importAsNew } });
  }

  getPrerequisite(): Observable<QuestionnairePrerequisite> {
    const url = `${this.authenticationRESTService.baseUrl()}/QuestionSets/prerequisite`;
    return this.http.get<QuestionnairePrerequisite>(url);
  }

  mapQuestionnaire(
    response: Questionnaires | any,
    idName: string,
    dataName = 'hierarchyId',
    selectable: boolean = true
  ): Questionnaires {
    return response?.map((element) => {
      element[idName] = element.questionSetId;
      element.label = element.name || element?.chapter?.text;
      element.data = element[dataName];
      element.styleClass = 'p-mt-2';
      element.selectable = selectable;
      element.id = element[dataName];
      element = this.introduceField(element, element.questionSetId, idName, selectable);
      return element;
    });
  }

  introduceField(
    questionnaire: Questionnaire,
    questionSetId: number,
    idName: string,
    selectable = true
  ) {
    questionnaire?.children?.forEach((input: Child) => {
      switch (input.type) {
        case 'Chapter':
          input.label = input.chapter.text;
          input.name = input.chapter.text;
          input[idName] = questionSetId;
          input.selectable = selectable;
          break;
        case 'QuestionSet':
          input.label = input.name;
          break;
        case 'Question':
          input.name = input.question.text;
          input.label = input.question.text;
          input.styleClass = 'question';
          input[idName] = questionSetId;
          break;
      }
      input.id = input.hierarchyId;
      input.data = input.hierarchyId;
      this.introduceField(input, questionSetId, idName, selectable);
    });
    return questionnaire;
  }
}
