import {
  DependentOn,
  FieldTypeEnum,
  FormConfig,
  ParamsValueByKeysFromUrl,
  StepBuilderInterface,
} from '@app/step-builder/models';
import { FieldServerInterface } from '../models/field-server.interface';
import { OptionsTypeEnum } from '../models/options-type.enum';
import { Injectable } from '@angular/core';
import { FieldModel } from '../models';
import { TreeNode } from 'primeng/api';

import { OptionParam } from '../models/option-param.enum';
import { FormGroup, Validators } from '@angular/forms';
import { MultiStepsLogicServiceInterface } from '@app/step-builder/models/multi-steps-service.interface';
import { parseData } from '@app/shared/utils/parse-data';

@Injectable({
  providedIn: 'root',
})
export class MultiStepsService implements MultiStepsLogicServiceInterface {
  constructor() {}

  dependencyCondition(field: FieldModel, form: FormGroup): boolean {
    const dependentConditions = field.dependentOn.map((value: DependentOn) => {
      value.dependentValue = parseData(value.dependentValue);
      return value;
    });
    return dependentConditions.every((value: DependentOn) => {
      if (value.singleSelect) {
        
        var formVal = form.value[value.dependentAttribute];

        // check if the selected values exists in the dependent list
        if (Array.isArray(formVal)) {
          // multiple values
          var all = formVal.length > 0;
          formVal.forEach(v => all = all && value.dependentValue.includes(v));
          return all;
        }
        else {
          // one value
          return value.dependentValue.includes(formVal);
        }
      }
      return form.value[value.dependentAttribute] === value.dependentValue;
    });
  }

   getParamsValueByKeysFromUrl(optionsUrl: string, formValues: any): ParamsValueByKeysFromUrl {
    const params: ParamsValueByKeysFromUrl = {};

    if (optionsUrl.includes(OptionParam.INCIDENTAREA_ID)) {
      params.AreaId = formValues.areaId;
    }
    if (optionsUrl.includes(OptionParam.INCIDENTCATEGORY_ID)) {
      params.CategoryId = formValues.categoryId;
    }
    if (optionsUrl.includes(OptionParam.INCIDENTDETAIL_ID)) {
      params.DetailId = formValues.detailId;
    }
    if (optionsUrl.includes(OptionParam.ORGANIZATION_ID)) {
      params.OrganizationId = formValues.locationId;
    }
    if(optionsUrl.includes(OptionParam.INCIDENTDETAILITEM_ID)) {
      params.DetailItemId = formValues.detailItemId;
    }
    return params;
  }

  manageFieldOptions(field, options, form) {
    field.options = options;
    const isOptions = Boolean(options?.length);
    field.required = Object.keys(field)?.includes('required') ? field?.required : isOptions;
    field.visible = (isOptions || field.type === FieldTypeEnum.AutoComplite);
    const validators = isOptions ? [Validators.required] : null;
    form.controls[field.property]?.setValidators(validators);
    form.controls[field.property]?.updateValueAndValidity();
  }

  getStepsAndFields({ incidentAttributes, attributeGroups }: FormConfig): StepBuilderInterface[] {
    const visibleSteps = attributeGroups.filter((step: any) => step.visible);
    const visibleFields = incidentAttributes.filter((field: FieldServerInterface) => field.visible);
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    return this.mapServerResponseToStepsWithFields({
      fields: visibleFields.map((field: FieldServerInterface) => {
        if (!Number.isInteger(field.attributeGroupId)) {
          const step = visibleSteps[visibleSteps.length - 1];
          field.attributeGroupId = step.id;
          field.canChange = false;
          field.visible = false;
        }
        if (isFirefox && field?.type === FieldTypeEnum.DefaultTimePicker) {
          field.type = FieldTypeEnum.TimePicker;
        }
        return this.generateDependence(field);
      }),
      steps: visibleSteps.map((step: any) => ({ ...step, fields: [] })),
    });
  }

  mapResults(results: any, optionsUrl) {
    const mapUsers = () => {
      return (results || []).map(
        (user: { fullName: string; userName: string; workspaceUserId: number, displayName: string }) => {
          return {
            name: user.displayName,
            displayName: user.displayName,
            id: user.workspaceUserId,
          };
        }
      );
    };
    const mapOrganizations = () => {
      const recursiveMapper = (results: any, parent: TreeNode = null) => {
        let treeNodes: TreeNode[] = [];

        if (parent != null) {
          treeNodes = parent.children;
        }
        if (treeNodes == undefined) {
          treeNodes = [];
        }

        results.forEach((o) => {
          const treeNode: TreeNode = {
            label: o.name,
            data: o.id,
            canSelect: o?.canSelect,
            expanded: parent ? false : true,
            icon: o?.icon,
          } as TreeNode;

          if (o.children) {
            treeNode.children = recursiveMapper(o.children, treeNode);
          }
          treeNodes.push(treeNode);
        });
        return treeNodes;
      };

      const array = recursiveMapper(results.children);

      return {
        array,
        response: results,
      };
    };
    switch (optionsUrl) {
      case OptionsTypeEnum.Users: {
        return mapUsers();
      }
      case OptionsTypeEnum.PersonCategory:
      case OptionsTypeEnum.Organizations: {
        return mapOrganizations();
      }
      default: {
        if (optionsUrl.includes(OptionsTypeEnum.Users)) {
          return mapUsers();
        } else if (optionsUrl.includes(OptionsTypeEnum.Organizations)) {
          return mapOrganizations();
        } else {
          return results;
        }
      }
    }
  }

  // todo. ser ut att sakna nivå 4 och 5...
  generateDependence(object) {
    switch (object?.property) {
      case 'areaId':
        object.dependent = 'categoryId';
        break;
      case 'categoryId':
        object.dependent = 'detailId';
        break;
      default:
        return object;
    }
    return object;
  }

  getFormValueByEntityValues(
    field: FieldModel,
    entityValues: any
  ): null | Date | string | { name: string; id: number, displayName: string } | number {
    if (!field.property || !entityValues) {
      return null;
    }

    if (
      [FieldTypeEnum.DatePicker, FieldTypeEnum.TimePicker].includes(field.type) &&
      entityValues[field.property]
    ) {
      return new Date(entityValues[field.property]);
    }

    if (
      field.type !== FieldTypeEnum.FormArray &&
      entityValues[field.property] &&
      Array.isArray(entityValues[field.property]) &&
      entityValues[field.property].some((item) => item.id)
    ) {
      return entityValues[field.property].map((item) => item.id);
    }
    if (entityValues[field.property] || field.type === FieldTypeEnum.TriStateCheckbox) {
      return entityValues[field.property];
    }

    if (field.property.includes('Id') && entityValues[field.property.replace('Id', '')]) {
      if (field.type === FieldTypeEnum.AutoComplite) {
        const value = entityValues[field.property.replace('Id', '')];
        return {
          name: value.fullName,
          displayName: value.displayName,
          id: value.workspaceUserId,
        };
      }

      return entityValues[field.property.replace('Id', '')].id;
    }
  }

  mapFormValuesToIncident(formValues: any): any {
    // TODO fix typing
    Object.keys(formValues).forEach((key: string) => {
      if (key.includes('Id') && formValues[key] && formValues[key].id) {
        formValues[key] = formValues[key].id;
      }
      if (
        formValues[key] &&
        Array.isArray(formValues[key]) &&
        formValues[key].every((formValue: any) => Number.isInteger(formValue.id))
      ) {
        formValues[key] = formValues[key].map((value: any) => ({ id: value.id }));
      }

      // filter arrays objects with null values
      if (
        formValues[key] &&
        Array.isArray(formValues[key]) &&
        formValues[key].length &&
        Object.keys(formValues[key][0]).length &&
        !formValues[key].every((formValue: any) => Number.isInteger(formValue.id))
      ) {
        formValues[key] = formValues[key].filter(
          (arrayValue: object) =>
            !Object.keys(arrayValue).every((valueKey: string) => arrayValue[valueKey] === null)
        );
      }
    });
    return formValues;
  }

  mapFormValuesToIncidentPreview(formValues: any): any {
    return formValues;
  }

  private mapServerResponseToStepsWithFields({
    fields,
    steps,
  }: {
    fields: FieldModel[];
    steps: Array<StepBuilderInterface>;
  }): StepBuilderInterface[] {
    fields.forEach((field: FieldModel) => {
      const step = steps.find((s) => s.id === field.attributeGroupId); // steps[field.attributeGroupId - 1];

      if (step) {
        if (!step.fields) {
          step.fields = [];
        }
        step.fields.push(field);
      }
    });
    return steps;
  }
}
