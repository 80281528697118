import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';

@Directive({ selector: '[copy-clipboard]' })
export class CopyClipboardDirective {

  constructor(private messageService: MessageService) {

  }

  @Input("copy-clipboard")
  public payload: string;

  @Input()
  public toastText: string;

  @Output()
  public copied: EventEmitter<string> = new EventEmitter<string>();

  @HostListener("click", ["$event"])
  public onClick(event: MouseEvent): void {

    event.preventDefault();
    if (!this.payload)
      return;

    const listener = (e: ClipboardEvent) => {
      const clipboard = e.clipboardData || window["clipboardData"];
      clipboard.setData("text", this.payload.toString());
      e.preventDefault();

      this.copied.emit(this.payload);

      this.messageService.add({
        severity: 'success',
        summary: this.toastText || this.payload
      });
    };

    document.addEventListener("copy", listener, false)
    document.execCommand("copy");
    document.removeEventListener("copy", listener, false);
  }
}
