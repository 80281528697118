import { Component, Input, OnInit } from '@angular/core';
import { Form, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.less']
})
export class IconPickerComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() controlName: string;
  icon = 'pi pi-ban';
  changed = false;

  ngOnInit(): void {
    if (!this.form?.value[this.controlName]) {
      this.form.get(this.controlName).reset();
    }
  }

  onIconSelect(icon: string): void {
    if (this.changed) {
      this.form.get(this.controlName).setValue(icon);
    } else {
      this.changed = true;
    }
  }
}
