import { FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

export function passwordValidator(form: FormGroup): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const userName = form?.get('firstName')?.value ?? '';
    const forbidden =
      (control.value || '').toString().toLowerCase() === userName.toString().toLowerCase();
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}

export function passwordMatch(group: FormGroup, fieldToCompare = 'password'): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const confirmPassword = control?.value || '';
    const password = group.get(fieldToCompare)?.value || '';
    const isMatch = password.toString().toLowerCase() === confirmPassword.toString().toLowerCase();
    return isMatch ? null : { error: true };
  };
}
