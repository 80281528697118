import { IncidentFullObject } from '@app/incidents/models/incident.interface';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { ListType } from '@app/shared/models/list-type.enum';
import { FieldModel } from '@app/step-builder/models';
import { IncidentService } from '@app/incidents/services/incident.service';
import { debounceTime } from 'rxjs/operators';
import { IncidentRESTService } from '@app/incidents/services/rest-services/incident.service';
import { FormConfigType } from '@app/step-builder/models/form-config-type.enum';

@Component({
  selector: 'app-link-incident',
  templateUrl: './link-incident.component.html',
  styleUrls: ['./link-incident.component.less'],
})
export class LinkIncidentComponent implements OnInit, OnDestroy {
  @Input() field: FieldModel;
  @Input() header: string;
  @Input() control: FormControl = new FormControl();
  @Input() parentIncidentId: number = null;
  @Output() setLinkedIncidentsEvent = new EventEmitter();
  files: any;
  displayDialog: boolean;
  loading: boolean;
  configTypes = FormConfigType;
  incidents: IncidentFullObject[] = [];
  attributes: any;
  linkedIncidents: IncidentFullObject[] = [];
  searchForm = this.fb.group({
    query: [''],
  });
  activeIncident: IncidentFullObject;

  constructor(
    private incidentRESTService: IncidentRESTService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    //this.subscribeOnSearchTyping();
    this.subscribeOnControlClear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.control) {
      this.getIncidents();
      this.linkedIncidents = this.control.value || [];
    }
  }

  getIncidents(query = '') {
    if (this.loading) {
      return;
    }
    else if (query === '') {
      this.setIncidents([]);
      return;
    }

    this.loading = true;
    this.incidentRESTService.get(ListType.All, null, query || '').subscribe((response: any) => {
      this.loading = false;

      let incidents = response.incidents && !response.incidents.length ? null : response.incidents;
      incidents = (incidents || []).filter((el) => {
        return el.incidentId !== this.parentIncidentId;
      });
      this.setIncidents(incidents);

      this.attributes = [];
      if (!response.attributes) {
        return;
      }
      this.attributes = response.attributes;
    });
  }

  setIncidents(arr): void {
    this.incidents = arr;
    this.field.options = this.incidents;
  }

  openListIncidentDialog() {
    this.displayDialog = true;
  }

  linkIncident(incident: IncidentFullObject) {
    if (incident.linked) {
      this.linkedIncidents = this.linkedIncidents.filter(
        (linkedIncident: IncidentFullObject) => linkedIncident.incidentId !== incident.incidentId
      );
    } else {
      this.linkedIncidents = [...this.linkedIncidents, { ...incident }];
    }
    this.setLinkedIncidentsEvent.emit(this.linkedIncidents);
    if (this.activeIncident) {
      this.setActiveIncident();
    }
  }

  setActiveIncident(incident: IncidentFullObject = null): void {
    this.activeIncident = incident;
  }

  private subscribeOnSearchTyping() {
    this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe((value: any) => {
      const query = value.query.trim();
      this.getIncidents(query);
    });
  }

  private subscribeOnControlClear(): void {
    this.control.valueChanges
      .subscribe((newControlValue: IncidentFullObject[]) => {
        if(!newControlValue?.length){
          this.linkedIncidents = [];
        }
      });
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      const query = this.searchForm.value.query.trim();
      this.getIncidents(query);
    }
  }
  ngOnDestroy() {
    this.control.patchValue(this.linkedIncidents);
  }
}
