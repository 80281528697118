import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { pluck, takeWhile, expand, filter, map, first } from 'rxjs/operators';

@Pipe({
  name: 'params',
})
export class OptionParamsPipe implements PipeTransform {
  constructor(private route: ActivatedRoute) {}

  transform(value: string) {
    const param = value.match(/[^{]+(?=\})/g);
    if (param) {
      const newValue = of(this.route).pipe(
        expand((route) => of(route.parent)),
        takeWhile((route) => !!route.parent),
        pluck('snapshot'),
        filter((a) => a?.data?.tabData?.name == param[0]),
        first(null, {}),
        map((item: any) => {
          return value?.replace(
            '${' + param[0] + '}',
            item?.params?.[item?.data?.tabData?.paramName]
          );
        })
      );
      return newValue;
    }
    return of(value);
  }
}
